import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AppService } from "@maas/services/app.service";
import { AuthGuard } from "@ramudden/guards";
import { Observable, of, switchMap } from "rxjs";

@Injectable()
export class AppConfiguredGuard implements CanActivate {
    private readonly authGuard = inject(AuthGuard);
    private readonly appService = inject(AppService);

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authGuard.canActivate(route, state).pipe(
            switchMap((isAuthenticated) => {
                if (!isAuthenticated) {
                    return of(false);
                }

                this.appService.restoreState();
                if (this.appService.config?.isConfigured) {
                    return of(true);
                }
                this.appService.clearState();
                return of(false);
            }),
        );
    }
}
