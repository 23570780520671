import { Injectable } from "@angular/core";
import { Constants } from "@ramudden/core/constants";
import { MeasuringPointUtils } from "@ramudden/core/utils";
import { IGroup, IGroupMeasuringPoint } from "@ramudden/data-access/models/group";
import { IProject } from "@ramudden/data-access/models/project";
import { ServiceRequestOptions } from "@ramudden/data-access/models/search";
import { VehicleDayOverview } from "@ramudden/data-access/models/vehicle-overview";
import { IMeasuringPointSummary } from "@ramudden/data-access/models/web";
import { CacheOptions } from "@ramudden/data-access/resource/api";
import { GroupApi } from "@ramudden/data-access/resource/group.api";
import { VehicleApi, VehicleDayOverviewSearchParameters } from "@ramudden/data-access/resource/vehicle.api";
import { Observable, forkJoin } from "rxjs";
import { SessionStorageService } from "./storage.service";

export class ValidationContext {
    allMeasuringPoints: IMeasuringPointSummary[];

    constructor(
        readonly from: Date,
        readonly to: Date,
        readonly groups: IGroup[],
        readonly measuringPoints: IMeasuringPointSummary[],
        readonly projects: IProject[],
    ) {
        if (!this.groups) this.groups = [];
        if (!this.measuringPoints) this.measuringPoints = [];
        if (!this.projects) this.projects = [];

        const groupMeasuringPoints = this.groups
            .selectMany<IGroup, IGroupMeasuringPoint>((x) => x.measuringPoints)
            .map((x) => x.measuringPoint);
        this.allMeasuringPoints = this.measuringPoints.concat(groupMeasuringPoints);

        for (const project of this.projects) {
            if (project.projectMeasuringPoints && project.projectMeasuringPoints.length > 0) {
                this.allMeasuringPoints = this.allMeasuringPoints.concat(
                    project.projectMeasuringPoints.map((x) => MeasuringPointUtils.toSummary(x.measuringPoint)),
                );
            }

            if (project.projectGroups && project.projectGroups.length > 0) {
                const mps = project.projectGroups
                    .map((x) => x.group)
                    .selectMany<IGroup, IGroupMeasuringPoint>((x) => x.measuringPoints)
                    .map((x) => x.measuringPoint);

                this.allMeasuringPoints = this.allMeasuringPoints.concat(mps);
            }
        }
    }
}

class StoredValidationContext {
    from: Date;
    to: Date;
    groups: number[];
    measuringPoints: IMeasuringPointSummary[];
    projects: IProject[];
}

@Injectable({
    providedIn: "root",
})
export class ValidationService {
    private context: ValidationContext;
    private fetchedStoredContext = false;

    constructor(
        private readonly vehicleApi: VehicleApi,
        private readonly sessionStorage: SessionStorageService,
        private readonly groupApi: GroupApi,
    ) {}

    setContext(context: ValidationContext) {
        if (context) {
            const normalizeTime = (date: Date) => {
                if (!date) return;
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
            };

            normalizeTime(context.from);
            normalizeTime(context.to);
        }

        this.context = context;
        this.storeContext();
    }

    async getContext(): Promise<ValidationContext> {
        if (!this.fetchedStoredContext) {
            this.fetchedStoredContext = true;

            const fetchedContext = await this.getStoredContext();
            this.context = this.context || fetchedContext;
        }

        return this.context;
    }

    getHours(): Date[] {
        const dates = new Array<Date>();

        for (let date = new Date(this.context.from); date < this.context.to.addDays(1); date = date.addHours(1)) {
            dates.push(new Date(date));
        }

        return dates;
    }

    getDates(): Date[] {
        const dates = new Array<Date>();

        for (let date = new Date(this.context.from); date < this.context.to.addDays(1); date = date.addDays(1)) {
            dates.push(new Date(date));
        }

        return dates;
    }

    getMeasuringPoint(id: number) {
        if (!this.context) return null;

        return this.context.allMeasuringPoints.find((x) => x.id === id);
    }

    getPreviousMeasuringPoint(currentMeasuringPoint: IMeasuringPointSummary): IMeasuringPointSummary {
        if (!this.context) return null;

        const index = this.context.allMeasuringPoints.indexOf(currentMeasuringPoint);
        if (index > 0) {
            return this.context.allMeasuringPoints[index - 1];
        }

        return null;
    }

    getNextMeasuringPoint(currentMeasuringPoint: IMeasuringPointSummary): IMeasuringPointSummary {
        if (!this.context) return null;

        const index = this.context.allMeasuringPoints.indexOf(currentMeasuringPoint);
        if (index < this.context.allMeasuringPoints.length - 1) {
            return this.context.allMeasuringPoints[index + 1];
        }

        return null;
    }

    load(
        measuringPoint: IMeasuringPointSummary,
        forceReload = true,
        loadNext = true,
    ): Observable<VehicleDayOverview[]> {
        let loadingNext = false;
        let results = 0;

        return new Observable<VehicleDayOverview[]>((observer) => {
            const cacheOptions = new CacheOptions();
            cacheOptions.pushCacheResult = !forceReload;

            this.vehicleApi
                .getVehicleDayOverviews$(this.getSearchParameters(measuringPoint), cacheOptions)
                .subscribe((dayOverviews) => {
                    // Fetch next MP so it gets cached
                    if (loadNext && !loadingNext) {
                        loadingNext = true;

                        const nextMeasuringPoint = this.getNextMeasuringPoint(measuringPoint);
                        if (nextMeasuringPoint) {
                            cacheOptions.pushCacheResult = false;
                            cacheOptions.pushCacheResultOnNotModified = false;
                            cacheOptions.stopOnCacheFound = true;

                            this.vehicleApi
                                .getVehicleDayOverviews$(this.getSearchParameters(nextMeasuringPoint), cacheOptions)
                                .subscribe(() => {});
                        }
                    }

                    results++;

                    observer.next(dayOverviews);

                    if ((results === 1 && (cacheOptions.stopOnCacheFound || forceReload)) || results === 2) {
                        observer.complete();
                    }
                });
        });
    }

    private getSearchParameters(measuringPoint: IMeasuringPointSummary): VehicleDayOverviewSearchParameters {
        const parameters = new VehicleDayOverviewSearchParameters();
        parameters.measuringPointId = measuringPoint.id;
        parameters.from = this.context.from;
        parameters.to = this.context.to;
        return parameters;
    }

    //#region Storage

    private storeContext() {
        if (this.context) {
            // Convert to tiny, json friendly format

            const toStore = new StoredValidationContext();
            toStore.from = this.context.from;
            toStore.to = this.context.to;
            toStore.groups = this.context.groups.map((x) => x.id);
            toStore.measuringPoints = this.context.measuringPoints.clone();
            toStore.projects = this.context.projects.clone();

            this.sessionStorage.setItem(Constants.validationStorageKey, JSON.stringify(toStore));
        } else {
            this.sessionStorage.removeItem(Constants.validationStorageKey);
        }
    }

    private async getStoredContext(): Promise<ValidationContext> {
        const stored = this.sessionStorage.getItem(Constants.validationStorageKey);
        if (!stored) return null;

        const parsed = JSON.parse(stored) as StoredValidationContext;

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("group", "measuringPoints");

        const groups = (await forkJoin(
            parsed.groups.map((x) => this.groupApi.get$(x, null, serviceRequestOptions).toPromise()),
        ).toPromise()) as IGroup[];

        const measuringPoints = parsed.measuringPoints;
        const projects = parsed.projects;

        return new ValidationContext(new Date(parsed.from), new Date(parsed.to), groups, measuringPoints, projects);
    }

    //#endregion Storage
}
