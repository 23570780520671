import { Injectable } from "@angular/core";
import { IParkingBan, ParkingBanCreator, ParkingBanUpdater } from "@ramudden/data-access/models/parking-ban";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ParkingBanApi extends ApiService<IParkingBan, ParkingBanCreator, ParkingBanUpdater> {
    override getRoute(): string {
        return "ParkingBans";
    }
}
