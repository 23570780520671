<m-modal
    [title]="title"
    #modal
>
    <ng-container body>
        <img
            [alt]="title"
            [src]="imageUrl"
        />
    </ng-container>
</m-modal>
