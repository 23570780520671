<label class="worker">
    <input
        class="worker__status input--option"
        [(ngModel)]="worker().isActive"
        type="checkbox"
    />

    @if (worker().isActive) {
        <m-svg
            iconName="circle-check"
            style="--icon-fill: var(--color-success)"
        />
    } @else {
        <m-svg
            iconName="circle-plus"
            style="--icon-fill: var(--color-neutral500)"
        />
    }

    <m-svg
        iconName="worker"
        style="--icon-width: 2rem; --icon-height: 2rem"
    />

    <span class="worker__name">{{ worker().firstName }} {{ worker().lastName }}</span>

    @if (worker().isTeamLeader) {
        <m-svg
            class="ml-auto"
            iconName="star"
            style="--icon-fill: var(--color-warning); --icon-width: 2rem; --icon-height: 2rem"
        />
    }
</label>
