import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExistsResult, ExistsValue, ISearchResult } from "@ramudden/data-access/models/search";
import { IUser, UserCreator, UserUpdater } from "@ramudden/data-access/models/user";
import Bowser from "bowser";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class UserApi extends ApiService<IUser, UserCreator, UserUpdater> {
    override getRoute(): string {
        return "Users";
    }

    override getDefaultHeaders(): HttpHeaders {
        return new HttpHeaders({
            "include-user": "organization,roles",
        });
    }

    getSelf$(): Observable<IUser> {
        const url = `${super.getUrl()}/Me`;
        return this.http.get<IUser>(url);
    }
    updateSelfLanguage$(language: string): Observable<any> {
        const url = `${super.getUrl()}/Me/language`;
        return this.http.put(url, { language });
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });
    }

    login$() {
        const url = `${super.getUrl()}/Login`;
        const browser = Bowser.getParser(window.navigator.userAgent);
        const name = `${browser.getBrowserName()} ${browser.getBrowserVersion()} - ${browser.getOSName()} ${browser.getOSVersion()}`;
        return this.http.post(url, { browser: name });
    }

    getProjectContacts$(organizationId: number): Observable<ISearchResult<IUser>> {
        const url = `${super.getUrl()}/ProjectContacts`;
        return this.http.get<ISearchResult<IUser>>(url, { params: { organizationId: organizationId.toString() } });
    }
}
