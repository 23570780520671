import { Injectable } from "@angular/core";
import { ITask, TaskCreator, TaskUpdater } from "@ramudden/data-access/models/task";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class TaskApi extends ApiService<ITask, TaskCreator, TaskUpdater> {
    override getRoute(): string {
        return "Tasks";
    }
}
