import { Injectable } from "@angular/core";
import { DrivingLaneCreator, IDrivingLane } from "@ramudden/data-access/models/driving-lane";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class DrivingLaneApi extends ApiService<IDrivingLane, DrivingLaneCreator, DrivingLaneCreator> {
    override getRoute(): string {
        return "DrivingLanes";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}
