<div class="toast">
    @for (message of messages; track $index) {
        <div
            class="toast__content"
            [ngClass]="message.type"
        >
            @if (message?.title) {
                <h3>{{ message.title }}</h3>
            }
            @if (message?.message) {
                <p>{{ message.message }}</p>
            }
        </div>
    }
</div>
