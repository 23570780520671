import { Injectable } from "@angular/core";
import { IDeviceDepotNavigator } from "@ramudden/data-access/models/device-depot";
import { ISearchResult, SearchParameters } from "@ramudden/data-access/models/search";
import { Observable } from "rxjs";
import { NavigatorWebApiService } from "./navigator-web-api";

@Injectable({
    providedIn: "root",
})
export class DeviceDepotNavigatorWebApi extends NavigatorWebApiService<IDeviceDepotNavigator> {
    override getRoute(): string {
        return "DeviceDepotNavigators";
    }

    search$(ownerId: number): Observable<ISearchResult<IDeviceDepotNavigator>> {
        const url = this.getUrl();

        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {
            ownerId: ownerId,
        };

        const options = this.createOptions(null, searchParameters, null);
        return this.http.get<ISearchResult<IDeviceDepotNavigator>>(url, options);
    }
}
