import { Injectable } from "@angular/core";
import { ILoginNotification, LoginNotificationCreator } from "@ramudden/data-access/models/login-notification";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class LoginNotificationApi extends ApiService<
    ILoginNotification,
    LoginNotificationCreator,
    LoginNotificationCreator
> {
    override getRoute(): string {
        return "LoginNotifications";
    }
}
