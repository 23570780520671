import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IDateInfoCollection } from "@ramudden/data-access/models/date-info";
import {
    DeviceCreator,
    DeviceUpdater,
    IBatteryHistory,
    IDevice,
    IDeviceLastLocationsCompared,
} from "@ramudden/data-access/models/device";
import { QrRegistrar, ReportIssue } from "@ramudden/data-access/models/qr";
import { IDateFilter } from "@ramudden/data-access/models/report-type";
import { ExistsResult, ExistsValue, ISearchResult, SearchParameters } from "@ramudden/data-access/models/search";
import { IUploadDailyMetric } from "@ramudden/data-access/models/upload";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class DeviceApi extends ApiService<IDevice, DeviceCreator, DeviceUpdater> {
    private readonly downloadFileService = inject(DownloadFileService);

    override getRoute(): string {
        return "Devices";
    }

    override getDefaultHeaders(): HttpHeaders {
        return new HttpHeaders({
            "include-device": "organization, measuringPoint",
        });
    }

    // exists$(existsValue: ExistsValue): Observable<ExistsResult> {
    //     const url = `${super.getUrl()}/Exists/${existsValue.value}`;
    //     return this.http.get<ExistsResult>(url);
    // }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        if (!existsValue.id) return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });

        return this.http.get<ExistsResult>(url, {
            params: { deviceId: existsValue.id.toString(), code: existsValue.value },
        });
    }

    updateAlarms$(): Observable<void> {
        const url = `${super.getUrl()}/UpdateAlarms`;
        return this.postOther$<void>(url, null);
    }

    getDailyUploadMetrics$(
        deviceId: number,
        skip: number,
        take: number,
    ): Observable<ISearchResult<IUploadDailyMetric>> {
        const urlAppend = `/${deviceId}/DailyUploadMetrics`;

        const searchParameters = new SearchParameters();
        searchParameters.skip = skip;
        searchParameters.take = take;

        return this.getOther$<ISearchResult<IUploadDailyMetric>>(urlAppend, searchParameters, null, false);
    }

    registerQr$(deviceId: number, qrRegistrar: QrRegistrar): Observable<void> {
        const url = `${super.getUrl()}/${deviceId}/RegisterQR`;
        return this.postOther$<void>(url, qrRegistrar);
    }

    reportIssue$(qrCode: string, issue: ReportIssue): Observable<void> {
        const url = `${super.getUrl()}/${qrCode}/ReportIssue`;
        return this.postOther$<void>(url, issue);
    }

    getBatteryHistory$(id: number, filter: IDateFilter): Observable<IBatteryHistory[]> {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = filter;
        return this.getOther$<IBatteryHistory[]>(`/${id}/BatteryHistory`, searchParameters);
    }

    getLocationHistoryImage$(id: number, filter: IDateFilter): Promise<DownloadedFile> {
        return this.downloadFileService.downloadBlob(
            `${super.getUrl()}/${id}/LocationHistoryImage?include.range.from=${filter.include.range.from}${filter.include.range.until ? "&include.range.until=" + filter.include.range.until : ""}`,
        );
    }

    getLastLocationsCompared$(id: number, filter: IDateFilter): Observable<IDeviceLastLocationsCompared> {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = filter;
        return this.getOther$<IDeviceLastLocationsCompared>(`/${id}/CompareLocations`, searchParameters);
    }

    getStatusDates$(id: number): Observable<IDateInfoCollection> {
        const url = `${super.getUrl()}/${id}/DaysWithStatuses`;
        return this.handleCaching(url);
    }

    updateWithFormData$(updater: DeviceUpdater, file?: { name: string; file: File }): Observable<IDevice> {
        const url = `${super.getUrl()}/${updater.id}/Form`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(updater));

        if (file) formData.append(file.name, file.file);

        return this.http.put<IDevice>(url, formData);
    }
}
