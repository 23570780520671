<m-modal
    [canClose]="false"
    title="{{'teamDialog.selectTeam' | translate}}"
>
    <ng-container body>
        @if (selectedPlannedEvent) {

        <div class="workers">
            @for (worker of plannedWorkers; track worker.id) {
            <app-worker
                (workerSelected)="onWorkerSelected($event)"
                [worker]="worker"
            />
            }
        </div>

        <div class="flex justify-content-center mt-16">
            @if (!(otherWorkers.length > 0)) {
            <button
                class="btn--yellow"
                (click)="showOtherWorkers()"
            >
                {{"teamDialog.showAll" | translate}}
            </button>
            } @else {
            <button
                class="btn--yellow"
                (click)="hideOtherWorkers()"
            >
                {{"teamDialog.hideAll" | translate}}
            </button>
            }
        </div>
        @if (otherWorkers.length > 0) {
        <div class="workers mt-16">
            @for (worker of otherWorkers; track worker.id) {
            <app-worker
                (workerSelected)="onWorkerSelected($event)"
                [worker]="worker"
            />
            }
        </div>
        } }
    </ng-container>
    <ng-container footer>
        <div class="flex align-items-center justify-content-between">
            <label class="inline-flex align-items-center gap-8">
                {{"teamDialog.createNewTeamFromExcludedWorkers" | translate}}
                <input
                    class="input--option"
                    [(ngModel)]="splitTeam"
                    type="checkbox"
                />
            </label>

            <button
                class="btn--yellow"
                (click)="navigateToVehicles()"
            >
                {{ (usedInSetup() ? "teamDialog.next" : "teamDialog.save") | translate }}
            </button>
        </div>
    </ng-container>
</m-modal>
