<content-default>
    <ng-container body>
        @if (isLoading) {
            <m-loader-icon />
        }

        <div class="flex flex-column gap-40">
            @if (todayAssignments.length) {
                <div>
                    <h2 class="mb-16">{{ "assignmentsPage.today" | translate }}</h2>

                    <div class="flex flex-column gap-16">
                        @for (assignment of todayAssignments; track $index) {
                            <app-assignment-card
                                [plannedAssignment]="assignment"
                                (assignmentClicked)="onAssignmentClicked($event)"
                            />
                        }
                    </div>
                </div>
            }

            @if (tomorrowAssignments.length) {
                <div>
                    <h2 class="mb-16">{{ "assignmentsPage.tomorrow" | translate }}</h2>

                    <div class="flex flex-column gap-16">
                        @for (assignment of tomorrowAssignments; track $index) {
                            <app-assignment-card [plannedAssignment]="assignment" />
                        }
                    </div>
                </div>
            }
        </div>
    </ng-container>
</content-default>
