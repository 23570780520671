import { JsonPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoaderIconComponent } from "@maas/components/loader-icon/loader-icon.component";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { DefaultComponent } from "@maas/layout/content/default/default.component";
import { AssignmentCardComponent } from "@maas/pages/assignments/assignment-card/assignment-card.component";
import { AppService } from "@maas/services/app.service";
import { AssignmentService } from "@maas/services/assignment.service";
import { TitleService } from "@maas/services/title.service";
import { TranslateModule } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { IPlannedAssignment } from "@ramudden/data-access/models/planned-event";

@Component({
    selector: "app-assignments",
    standalone: true,
    templateUrl: "./assignments.component.html",
    styleUrl: "./assignments.component.scss",
    imports: [DefaultComponent, JsonPipe, AssignmentCardComponent, SvgComponent, LoaderIconComponent, TranslateModule],
})
export class AssignmentsComponent implements OnInit, OnDestroy {
    private readonly appService = inject(AppService);
    private readonly router = inject(Router);
    private readonly assignmentService = inject(AssignmentService);
    private readonly titleService = inject(TitleService);
    private readonly subscriptionManager = new SubscriptionManager();

    todayAssignments: IPlannedAssignment[] = [];
    tomorrowAssignments: IPlannedAssignment[] = [];
    isLoading: boolean = false;

    async ngOnInit() {
        this.subscriptionManager.add(
            "refreshClicked",
            this.titleService.refreshClicked$.subscribe(() => {
                this.refetchPlannedEvents();
            }),
        );

        this.titleService.title = "Markings as a Service";

        if (!this.appService.config.isConfigured) {
            this.router.navigate(["/setup"]);
        }

        this.isLoading = true;
        this.todayAssignments = await this.assignmentService.getTodayAssignments();
        this.tomorrowAssignments = await this.assignmentService.getTomorrowAssignments();
        this.isLoading = false;
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected onAssignmentClicked(plannedAssignment: IPlannedAssignment) {
        this.assignmentService.selectedPlannedAssignment = plannedAssignment;
        this.router.navigate(["assignment", plannedAssignment.id, "details"]);
    }

    protected async refetchPlannedEvents() {
        this.isLoading = true;
        this.todayAssignments = await this.assignmentService.getTodayAssignments();
        this.tomorrowAssignments = await this.assignmentService.getTomorrowAssignments();
        this.isLoading = false;
    }
}
