import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface ToastMessage {
    type: "success" | "error" | "info" | "warning";
    message: string;
    title?: string;
}

@Injectable({
    providedIn: "root",
})
export class ToastService {
    private toastSubject = new Subject<ToastMessage>();
    toastState$ = this.toastSubject.asObservable();

    showSuccess(message: string, title?: string) {
        this.toastSubject.next({ type: "success", message, title });
    }

    showError(message: string, title?: string) {
        this.toastSubject.next({ type: "error", message, title });
    }

    showInfo(message: string, title?: string) {
        this.toastSubject.next({ type: "info", message, title });
    }

    showWarning(message: string, title?: string) {
        this.toastSubject.next({ type: "warning", message, title });
    }
}
