<dialog
    class="modal"
    #modal
>
    <div
        class="modal__content"
        #modalContent
    >
        <header class="modal__header">
            <h3>
                {{ title() }}
            </h3>

            @if (canClose()) {
                <button
                    class="btn--icon modal__close"
                    (click)="closeModal()"
                >
                    <m-svg iconName="close" />
                </button>
            }
        </header>

        <div class="modal__body">
            <ng-content select="[body]"></ng-content>
        </div>

        @if (hasFooter) {
            <footer class="modal__footer">
                <ng-content select="[footer]"></ng-content>
            </footer>
        }
    </div>
</dialog>
