import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, computed, input, output } from "@angular/core";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { MomentDatePipe, ShortDateTimePipe } from "@maas/pipes/datetime.pipe";
import { TranslateEnumPipe } from "@maas/pipes/translate-enum.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentStatus } from "@ramudden/data-access/models/assignment";
import { IPlannedAssignment } from "@ramudden/data-access/models/planned-event";

@Component({
    selector: "app-assignment-card",
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        MomentDatePipe,
        ShortDateTimePipe,
        SvgComponent,
        TranslateEnumPipe,
        TranslateModule,
    ],
    templateUrl: "./assignment-card.component.html",
    styleUrl: "./assignment-card.component.scss",
})
export class AssignmentCardComponent {
    plannedAssignment = input.required<IPlannedAssignment>();
    assignmentClicked = output<IPlannedAssignment>();

    assignment = computed(() => {
        return this.plannedAssignment().assignment;
    });

    name = computed(() => {
        if (this.assignment()?.parentAssignment)
            return `${this.assignment()?.parentAssignment.name} - ${this.assignment().name}`;
        else {
            return this.assignment()?.name;
        }
    });

    organization = computed(() => {
        return (
            this.assignment().project?.organization?.name ||
            this.assignment().parentAssignment?.project?.organization?.name
        );
    });

    description = computed(() => {
        return this.assignment().project?.description || this.assignment().parentAssignment?.project?.description;
    });

    address = computed(() => {
        const address = this.assignment().location.address;
        return `${address.line1}, ${address.zipCode} ${address.city}`;
    });

    isMachineWork = computed(() => {
        return this.assignment().isMachineWork;
    });

    protected getGoogleMapsLink(): string {
        const coordinate = this.assignment().location.coordinate;

        return `https://www.google.com/maps/place/${coordinate.latitude},${coordinate.longitude}/@${coordinate.latitude},${coordinate.longitude},17z`;
    }

    getStatusColor(): string {
        const statusId = this.assignment()?.currentStatus?.statusId;

        if (!statusId) {
            return "var(--color-default)";
        }

        switch (statusId) {
            case AssignmentStatus.Cancelled:
                return "var(--color-not-started)";
            case AssignmentStatus.Finished:
                return "var(--color-finished)";
            case AssignmentStatus.InProgress:
                return "var(--color-in-progress)";
            default:
                return "var(--color-default)";
        }
    }
}
