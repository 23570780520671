import { Injectable } from "@angular/core";
import { IDateInfoCollection } from "@ramudden/data-access/models/date-info";
import { GroupCreator, GroupUpdater, IGroup } from "@ramudden/data-access/models/group";
import { ExistsResult, ExistsValue } from "@ramudden/data-access/models/search";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class GroupApi extends ApiService<IGroup, GroupCreator, GroupUpdater> {
    override getRoute(): string {
        return "Groups";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        if (!existsValue.ownerId) return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });

        return this.http.get<ExistsResult>(url, {
            params: { code: existsValue.value, ownerId: existsValue.ownerId.toString() },
        });
    }

    getDataDates$(groupId: number): Observable<IDateInfoCollection> {
        const url = `${super.getUrl()}/${groupId}/Dates`;
        return this.handleCaching(url);
    }
}
