<m-modal
    [canClose]="false"
    title="{{'userDialog.title' | translate}}"
>
    <ng-container body>
        @if (teamForm) {
        <form [formGroup]="teamForm">
            <div class="form__fields">
                <div class="form__field">
                    <m-dropdown
                        id="worker"
                        (selectionChanged)="workerChanged($event)"
                        [options]="workers"
                        [placeHolder]="'teamsPage.form.worker'"
                        formControlName="worker"
                    />
                </div>

                <div class="form__field">
                    <label
                        class="label"
                        for="team"
                    >
                        {{"userDialog.team" | translate}}
                    </label>

                    <m-dropdown
                        id="team"
                        [options]="teams"
                        [placeHolder]="'teamsPage.form.team'"
                        #teamDropdown
                        formControlName="team"
                    />
                </div>

                <div class="form__field">
                    <label
                        class="label"
                        for="language"
                    >
                        {{"userDialog.language" | translate}}
                    </label>

                    <m-dropdown
                        id="language"
                        (selectionChanged)="languageChanged($event)"
                        [options]="languages"
                        [placeHolder]="'teamsPage.form.language'"
                        formControlName="language"
                    />
                </div>
            </div>
        </form>
        }
    </ng-container>

    <ng-container footer>
        @if (teamForm) {
        <div class="flex align-items-center justify-content-between gap-16">
            <button
                class="btn"
                type="button"
            >
                {{"userDialog.logout" | translate}}
            </button>

            <button
                class="btn--yellow"
                (click)="submit()"
                [disabled]="!teamForm.valid"
                type="button"
            >
                {{"userDialog.next" | translate}}
            </button>
        </div>
        }
    </ng-container>
</m-modal>
