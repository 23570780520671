<label class="vehicle">
    <input
        class="vehicle__status input--option"
        [(ngModel)]="vehicle().isActive"
        type="checkbox"
    />

    @if (vehicle().isActive) {
        <m-svg
            iconName="circle-check"
            style="--icon-fill: var(--color-success)"
        />
    } @else {
        <m-svg
            iconName="circle-plus"
            style="--icon-fill: var(--color-neutral500)"
        />
    }

    <m-svg
        [iconName]="vehicle().isWJet ? 'wjet' : 'vehicle'"
        style="--icon-width: 2rem; --icon-height: 2rem"
    />

    <span class="vehicle__name">{{ vehicle().deviceCode }}</span>
</label>
