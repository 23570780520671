import { inject, Injectable } from "@angular/core";
import { AttachmentCreator, AttachmentUpdater, IAttachment } from "@ramudden/data-access/models/attachment";
import { ICoordinate, LocationCreator } from "@ramudden/data-access/models/location";
import { IParkingBan, ParkingBanCreator, ParkingBanUpdater } from "@ramudden/data-access/models/parking-ban";
import {
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater,
} from "@ramudden/data-access/models/parking-ban-exception";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { ISignScan, SignScanCreator, SignScanUpdater } from "@ramudden/data-access/models/sign-scan";
import { AttachmentApi } from "@ramudden/data-access/resource/attachment.api";
import { ParkingBanExceptionApi } from "@ramudden/data-access/resource/parking-ban-exception.api";
import { ParkingBanApi } from "@ramudden/data-access/resource/parking-ban.api";
import { SignScanApi } from "@ramudden/data-access/resource/sign-scan.api";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ParkingBanService {
    private readonly attachmentApi = inject(AttachmentApi);
    private readonly parkingBanApi = inject(ParkingBanApi);
    private readonly parkingBanExceptionApi = inject(ParkingBanExceptionApi);
    private readonly signScanApi = inject(SignScanApi);

    //region Parking bans
    public async createParkingBan(parkingBan: IParkingBan): Promise<IParkingBan> {
        const creator = Object.assign(new ParkingBanCreator(), parkingBan);
        return await firstValueFrom(this.parkingBanApi.create$(creator));
    }

    public async updateParkingBan(parkingBan: IParkingBan): Promise<IParkingBan> {
        const updater = new ParkingBanUpdater().init(parkingBan);
        return await firstValueFrom(this.parkingBanApi.update$(updater));
    }

    public async deleteParkingBan(parkingBanId: number): Promise<void> {
        await firstValueFrom(this.parkingBanApi.delete$(parkingBanId));
    }

    public async deleteParkingBanAttachment(attachmentId: number) {
        if (attachmentId <= 0) return;

        await firstValueFrom(this.attachmentApi.delete$(attachmentId));
    }

    public async createParkingBanAttachment(
        parkingBan: IParkingBan,
        dataUrl: string,
        description: string,
    ): Promise<IAttachment> {
        const imageName = `${parkingBan.id}_${new Date().getTime()}.png`.replace(/ /g, "_");
        const image = this.dataURLtoFile(dataUrl, imageName);

        const creator = new AttachmentCreator();
        creator.description = description;
        creator.parkingBanId = parkingBan.id;
        creator.name = imageName;
        creator.typeId = 3;

        return await firstValueFrom(this.attachmentApi.upload$(creator, image));
    }

    public async updateParkingBanAttachment(attachment: IAttachment, parkingBanId: number): Promise<IAttachment> {
        const updater = new AttachmentUpdater(attachment);
        updater.parkingBanId = parkingBanId;
        return await firstValueFrom(this.attachmentApi.update$(updater));
    }

    public async getAllParkingBans(assignmentId: number): Promise<IParkingBan[]> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("ParkingBan", "Location");
        serviceRequestOptions.includes.add("ParkingBan", "Exceptions");

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals)];

        return await firstValueFrom(this.parkingBanApi.getAll$(searchParameters, null, serviceRequestOptions));
    }

    public async getParkingBan(parkingBanId: number): Promise<IParkingBan> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Attachment", "Type");
        serviceRequestOptions.includes.add("ParkingBan", "Assignment");
        serviceRequestOptions.includes.add("ParkingBan", "Exceptions");
        serviceRequestOptions.includes.add("ParkingBan", "Location");
        serviceRequestOptions.includes.add("ParkingBan", "Photos");
        serviceRequestOptions.includes.add("ParkingBan", "SignScans");
        serviceRequestOptions.includes.add("ParkingBanException", "Location");
        serviceRequestOptions.includes.add("ParkingBanException", "Photos");
        serviceRequestOptions.includes.add("SignScan", "Location");
        serviceRequestOptions.includes.add("SignScan", "Photos");

        return await firstValueFrom(this.parkingBanApi.get$(parkingBanId, null, serviceRequestOptions));
    }
    //endregion

    //region Sign scans
    public async createParkingBanSignScan(
        parkingBan: IParkingBan,
        organizationId: number,
        coordinate: ICoordinate,
        qrCode: string,
    ): Promise<ISignScan> {
        const locationCreator = new LocationCreator();
        locationCreator.coordinate = coordinate;
        locationCreator.ownerId = organizationId;

        const signScanCreator = new SignScanCreator({
            name: qrCode,
            signCode: qrCode,
            checkIn: new Date(),
            parkingBan: parkingBan,
            location: locationCreator,
        } as ISignScan);

        return await firstValueFrom(this.signScanApi.create$(signScanCreator));
    }

    public async deleteParkingBanSignScan(signScanId: number): Promise<void> {
        if (signScanId <= 0) return;

        await firstValueFrom(this.signScanApi.delete$(signScanId));
    }

    public async updateParkingBanSignScan(signScan: ISignScan): Promise<ISignScan> {
        const signScanUpdater = new SignScanUpdater(signScan);
        return await firstValueFrom(this.signScanApi.update$(signScanUpdater));
    }
    //endregion

    //region Exceptions
    public async createParkingBanException(
        parkingBan: IParkingBan,
        licensePlate: string,
        organizationId: number,
    ): Promise<IParkingBanException> {
        const locationCreator = new LocationCreator();
        locationCreator.coordinate = parkingBan.location.coordinate;
        locationCreator.ownerId = organizationId;
        locationCreator.code = licensePlate;

        const creator = new ParkingBanExceptionCreator();
        creator.licensePlate = licensePlate;
        creator.parkingBanId = parkingBan.id;
        creator.timestamp = new Date();
        creator.location = locationCreator;

        return await firstValueFrom(this.parkingBanExceptionApi.create$(creator));
    }

    public async deleteParkingBanException(parkingBanExceptionId: number): Promise<void> {
        if (parkingBanExceptionId <= 0) return;

        await firstValueFrom(this.parkingBanExceptionApi.delete$(parkingBanExceptionId));
    }

    public async updateParkingBanException(exception: IParkingBanException): Promise<IParkingBanException> {
        const exceptionUpdater = new ParkingBanExceptionUpdater(exception);
        return await firstValueFrom(this.parkingBanExceptionApi.update$(exceptionUpdater));
    }

    public async createParkingBanExceptionAttachment(
        exception: IParkingBanException,
        dataUrl: string,
    ): Promise<IAttachment> {
        const imageName = `${exception.id}_${new Date().getTime()}.png`.replace(/ /g, "_");
        const file = this.dataURLtoFile(dataUrl, imageName);

        const creator = new AttachmentCreator();
        creator.parkingBanExceptionId = exception.id;
        creator.name = imageName;
        creator.typeId = 3;

        return await firstValueFrom(this.attachmentApi.upload$(creator, file));
    }
    //endregion

    private dataURLtoFile(dataUrl: string, fileName: string) {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }
}
