import { ILocation, LocationCreator } from "./location";
import { IServiceModelBase } from "./servicemodelbase";
import { IUserSummary } from "./user";

export interface IAttachment extends IServiceModelBase {
    createDate: Date;
    creator: IUserSummary;
    name: string;
    description: string;
    url: string;
    type: IAttachmentType;

    // Only set & used in GUI
    preview: string;

    location?: ILocation;
}

export class AttachmentCreator {
    name: string;
    description: string;
    typeId: number;
    journalId?: number;
    signScanId?: number;
    parkingBanExceptionId?: number;
    workerId?: number;
    taskId?: number;
    expenseId?: number;
    creditId?: number;
    parkingBanId?: number;

    location: ILocation;
    locationCreator: LocationCreator;
}

export class AttachmentUpdater extends AttachmentCreator {
    constructor(existing: IAttachment) {
        super();

        this.id = existing.id;
        this.name = existing.name;
        this.description = existing.description;
        this.typeId = existing.type ? existing.type.id : null;
    }

    id: number;
}

export interface IAttachmentType extends IServiceModelBase {
    code: string;
}

export class AttachmentTypeCreator {
    code: string;
}

export class AttachmentContext {
    constructor(
        public contextType:
            | "journal"
            | "worker"
            | "task"
            | "parkingBan"
            | "parkingBanException"
            | "signScan"
            | "expense"
            | "credit",
        public contextEntityId: number,
    ) {}
}
