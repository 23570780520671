import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "content-stepper",
    standalone: true,
    imports: [],
    templateUrl: "./stepper.component.html",
    styleUrl: "./stepper.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutStepperComponent {}

