import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "@maas/components/modal/modal.component";

@Component({
    selector: "image-viewer-dialog",
    standalone: true,
    templateUrl: "./image-viewer.dialog.html",
    imports: [ModalComponent],
})
export class ImageViewerDialog {
    @ViewChild("modal") modal: ModalComponent;

    title: string;
    imageUrl: string;

    openModal(title: string, imageUrl: string, event: Event) {
        this.title = title;
        this.imageUrl = imageUrl;

        this.modal.openModal(event);
    }
}
