import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    input,
    ViewChild,
} from "@angular/core";
import { SvgComponent } from "@maas/components/svg/svg.component";

@Component({
    selector: "m-modal",
    standalone: true,
    imports: [CommonModule, SvgComponent],
    templateUrl: "./modal.component.html",
    styleUrl: "./modal.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit {
    @ViewChild("modal", { static: true }) modalElement!: ElementRef<HTMLDialogElement>;
    @ViewChild("modalContent", { static: true }) modalContentElement!: ElementRef;

    hasFooter = false;

    @ContentChild("footer") set setFooter(footer: HTMLElement) {
        this.hasFooter = !!footer;
    }

    title = input.required<string>();
    canClose = input<boolean>(true);

    get modalOpen() {
        return this.modalElement.nativeElement.open;
    }

    openModal(event?: Event) {
        if (event) event.stopPropagation();
        this.modalElement.nativeElement.showModal();
    }

    closeModal() {
        this.modalElement.nativeElement.close();
    }

    isOutsideOfElement(event: Event, element: any) {
        return !element.contains(event.target);
    }

    ngAfterViewInit() {
        if (this.canClose()) {
            window.addEventListener("click", (event) => {
                let userClickedOutside = this.isOutsideOfElement(event, this.modalContentElement.nativeElement);

                if (userClickedOutside) {
                    this.closeModal();
                }
            });
        }
    }
}
