<m-modal
    #modal
    title="{{'parkingBanPage.updateLocation' | translate}}"
>
    <ng-container body>
        <app-map
            (coordinatesChanged)="onCoordinatesChanged($event)"
            (mapLoaded)="onMapLoaded()"
            [coordinates]="coordinates"
            #appMap
        />
    </ng-container>
    <ng-container footer>
        <div class="flex justify-content-between gap-16">
            <button
                class="btn"
                (click)="useCurrentCoordinates(appMap.getCurrentCoordinates())"
            >
                <m-svg iconName="location-crosshair" />
                {{"parkingBanPage.useCurrentLocation" | translate}}
            </button>

            <button
                class="btn--yellow"
                (click)="onSave(); modal.closeModal()"
            >
                {{"parkingBanPage.save" | translate}}
            </button>
        </div>
    </ng-container>
</m-modal>
