import { Component, ElementRef, output, ViewChild } from "@angular/core";
import { ModalComponent } from "@maas/components/modal/modal.component";
import { SvgComponent } from "../svg/svg.component";

@Component({
    selector: "app-photo",
    templateUrl: "./photo.component.html",
    styleUrl: "./photo.component.scss",
    imports: [ModalComponent, SvgComponent],
    standalone: true,
})
export class PhotoComponent {
    @ViewChild(ModalComponent, { static: true }) modal!: ModalComponent;
    @ViewChild("video", { static: true }) video: ElementRef<HTMLVideoElement>;
    @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;

    snapshotSubmit = output<string>();

    private stream: MediaStream | null = null;
    public webcamImage: string = undefined;

    public open(event: Event) {
        this.startWebcam().then(() => {
            this.modal.openModal(event);
        });
    }

    private async startWebcam() {
        this.resetView();

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            this.video.nativeElement.srcObject = this.stream;
        } catch (err) {
            console.error("Could not start webcam ", err);
        }
    }

    private resetView() {
        this.canvas.nativeElement.style.display = "none";
        this.video.nativeElement.style.display = "block";
        this.webcamImage = undefined;
    }

    public retakeSnapshot(): void {
        this.resetView();
    }

    public triggerSnapshot(): void {
        const video = this.video.nativeElement;
        const canvas = this.canvas.nativeElement;
        const context = canvas.getContext("2d");

        if (context) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            this.webcamImage = canvas.toDataURL("image/png");
            this.video.nativeElement.style.display = "none";
            this.canvas.nativeElement.style.display = "block";
        }
    }

    public submitSnapshot(): void {
        const canvas = this.canvas.nativeElement;
        const imageDataUrl = canvas.toDataURL("image/png");
        this.snapshotSubmit.emit(imageDataUrl);
        this.modal.closeModal();
        this.stopWebcam();
    }

    private stopWebcam() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
            this.stream = null;
        }
    }
}
