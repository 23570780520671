import { IAttachment } from "./attachment";
import { ILocation, LocationCreator } from "./location";
import { IParkingBan } from "./parking-ban";
import { IServiceModelBase } from "./servicemodelbase";

export interface IParkingBanException extends IServiceModelBase {
    licensePlate: string;
    location: ILocation;
    timestamp: Date;

    photoPreview: string; // FE only
    photos: IAttachment[];
    parkingBan: IParkingBan;
}

export class ParkingBanExceptionCreator {
    licensePlate: string;
    timestamp: Date;
    parkingBanId?: number;

    locationId?: number;
    location?: LocationCreator;
}

export class ParkingBanExceptionUpdater extends ParkingBanExceptionCreator {

    constructor(model: IParkingBanException) {
        super();
        this.id = model.id;
        this.licensePlate = model.licensePlate;
        this.timestamp = model.timestamp;
        this.parkingBanId = model.parkingBan ? model.parkingBan.id : undefined;
    }

    id: number;
}