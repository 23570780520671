import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderIconComponent } from "@maas/components/loader-icon/loader-icon.component";
import { ModalComponent } from "@maas/components/modal/modal.component";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { DefaultComponent } from "@maas/layout/content/default/default.component";
import { ImageViewerDialog } from "@maas/pages/assignment-container/assignment-details/dialogs/image-viewer/image-viewer.dialog";
import { PdfViewerDialog } from "@maas/pages/assignment-container/assignment-details/dialogs/pdf-viewer/pdf-viewer.dialog";
import { TeamDialog, TeamDialogOutput } from "@maas/pages/setup/dialogs/team/team.dialog";
import { ISelectedVehicle, VehicleDialog } from "@maas/pages/setup/dialogs/vehicle/vehicle.dialog";
import { AppService } from "@maas/services/app.service";
import { AssignmentService } from "@maas/services/assignment.service";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentStatus } from "@ramudden/data-access/models/assignment";
import { IAttachment } from "@ramudden/data-access/models/attachment";
import {
    IPlannedAssignment,
    IPlannedAssignmentCustomTeam,
    IPlannedAssignmentCustomTeamMember,
} from "@ramudden/data-access/models/planned-event";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { JournalApi } from "@ramudden/data-access/resource/journal.api";
import { PlannedAssignmentApi } from "@ramudden/data-access/resource/planned-assignment.api";
import { DomainDataService } from "@ramudden/services";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-assignment-details",
    standalone: true,
    imports: [
        CommonModule,
        DefaultComponent,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        SvgComponent,
        ModalComponent,
        ImageViewerDialog,
        PdfViewerDialog,
        VehicleDialog,
        TeamDialog,
        LoaderIconComponent,
    ],
    templateUrl: "./assignment-details.component.html",
    styleUrl: "./assignment-details.component.scss",
})
export class AssignmentDetailsComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly appService = inject(AppService);
    private readonly journalApi = inject(JournalApi);
    private readonly plannedAssignmentApi = inject(PlannedAssignmentApi);

    plannedAssignment: IPlannedAssignment;
    attachments: IAttachment[] = [];
    plannedEventForm: FormGroup;
    isLoading = false;

    async ngOnInit() {
        this.isLoading = true;
        this.plannedAssignment = await this.assignmentService.getSelectedPlannedAssignment();
        this.fetchAttachments();
        this.createForm();
        this.isLoading = false;
    }

    // region Get
    fileIsPdf(fileName: string): boolean {
        return fileName.toLowerCase().endsWith(".pdf");
    }

    fileIsImage(fileName: string): boolean {
        return (
            fileName.toLowerCase().endsWith(".jpg") ||
            fileName.toLowerCase().endsWith(".jpeg") ||
            fileName.toLowerCase().endsWith(".png")
        );
    }

    get teamMembers(): string {
        if (this.plannedAssignment.customTeam.length > 0) {
            return this.plannedAssignment.customTeam
                .map((ct) => `${ct.worker.firstName} ${ct.worker.lastName}`)
                .join(", ");
        }
        return this.appService.selectedWorkers.map((worker) => `${worker.firstName} ${worker.lastName}`).join(", ");
    }

    get vehicles() {
        if (this.plannedAssignment.customVehicles.length > 0) {
            return this.plannedAssignment.customVehicles.map((cv) => cv.device.code).join(", ");
        }
        return this.appService.selectedVehicles.map((vehicle) => vehicle.deviceCode).join(", ");
    }

    get siteActive() {
        return (
            this.plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            this.plannedAssignment.currentTeamStatus?.teamDeparture === undefined
        );
    }

    get siteInactive() {
        return (
            this.plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            this.plannedAssignment.currentTeamStatus?.teamDeparture !== undefined
        );
    }
    //endregion

    //region Form
    private createForm() {
        const generalForm = this.createGeneralForm();
        const administrativeResponsibleForm = this.createUserForm("administrativeResponsible");
        const responsibleClientForm = this.createUserForm("responsibleClient");
        const executiveResponsibleForm = this.createUserForm("executiveResponsible");

        const assignment = this.plannedAssignment.assignment;
        const isFinished: boolean =
            assignment.currentStatus &&
            (assignment.currentStatus.statusId === AssignmentStatus.Finished ||
                assignment.currentStatus.statusId === AssignmentStatus.Cancelled);
        this.plannedEventForm = this.formBuilder.group({
            generalForm,
            administrativeResponsibleForm,
            responsibleClientForm,
            executiveResponsibleForm,
            isFinished: new FormControl(isFinished),
        });

        if (isFinished) {
            this.plannedEventForm.get("isFinished").disable();
        }
    }

    private createGeneralForm() {
        const description = this.plannedAssignment.assignment.description;
        const remarks = this.plannedAssignment.note;

        const taskType = this.domainDataService.translateEnum("taskType", this.plannedAssignment.assignment.taskTypeId);
        const material = this.domainDataService.translateEnum(
            "signMaterial",
            this.plannedAssignment.assignment.signMaterialId,
        );
        const isMachineWork = this.plannedAssignment.assignment.isMachineWork;

        const generalForm = this.formBuilder.group({
            description: new FormControl(description),
            isMachineWork: new FormControl(isMachineWork),
            material: new FormControl(material),
            remarks: new FormControl(remarks),
            taskType: new FormControl(taskType),
        });

        generalForm.get("description").disable();
        generalForm.get("description").patchValue(description);
        generalForm.get("isMachineWork").disable();
        generalForm.get("material").disable();
        generalForm.get("taskType").disable();

        return generalForm;
    }

    private createUserForm(field: string) {
        const assignment = this.plannedAssignment.assignment;

        const user = !assignment.parentAssignment ? assignment[field] : assignment.parentAssignment[field];

        const name = `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;
        const email = user?.email ?? "";
        const phoneNumber = user?.phoneNumber ?? "";
        const mobilePhone = user?.gsm ?? "";

        const userForm = this.formBuilder.group({
            name: new FormControl(name),
            email: new FormControl(email),
            phoneNumber: new FormControl(phoneNumber),
            mobilePhone: new FormControl(mobilePhone),
        });

        userForm.get("name").disable();
        userForm.get("email").disable();
        userForm.get("phoneNumber").disable();
        userForm.get("mobilePhone").disable();

        return userForm;
    }

    //endregion

    //region Site
    async startSite() {
        this.isLoading = true;
        const plannedAssignmentId = this.plannedAssignment.id;
        this.plannedAssignment.currentTeamStatus = await firstValueFrom(
            this.plannedAssignmentApi.marktTeamArrival$(plannedAssignmentId),
        );
        this.assignmentService.selectedPlannedAssignment = { ...this.plannedAssignment };
        this.isLoading = false;
    }

    async endSite() {
        this.isLoading = true;
        const plannedAssignmentId = this.plannedAssignment.id;
        this.plannedAssignment.currentTeamStatus = await firstValueFrom(
            this.plannedAssignmentApi.markTeamDeparture$(plannedAssignmentId),
        );
        this.assignmentService.selectedPlannedAssignment = { ...this.plannedAssignment };
        this.isLoading = false;
    }
    //endregion

    async onTeamDialogClosed(teamDialogOutput: TeamDialogOutput) {
        this.isLoading = true;
        const customTeamMembers = teamDialogOutput.selectedWorkers.map(
            (sw, index) => ({ workerId: sw.id, displayOrder: index }) as IPlannedAssignmentCustomTeamMember,
        );
        const customTeam: IPlannedAssignmentCustomTeam = { teamMembers: customTeamMembers };
        this.plannedAssignment = await firstValueFrom(
            this.plannedAssignmentApi.updateCustomTeam(this.plannedAssignment.id, customTeam),
        );
        this.assignmentService.selectedPlannedAssignment = { ...this.plannedAssignment };
        this.isLoading = false;
    }

    onVehicleDialogClosed(selectedVehicles: ISelectedVehicle[]) {
        // const customVehicles = selectedVehicles.map((sv) => ({});

        console.log(selectedVehicles);
    }

    async toggleFinished() {
        this.isLoading = true;
        const success = await this.assignmentService.setAssignmentFinished();
        this.plannedEventForm.get("isFinished").patchValue(success);
        this.plannedEventForm.get("isFinished").disable();
        this.isLoading = false;
    }

    //region API Calls
    private async fetchAttachments() {
        const assignment = this.plannedAssignment.assignment;

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Journal", "Attachments");

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("IsAdminOnly", false, FilterOperator.equals)];

        const journals = (
            await firstValueFrom(
                this.journalApi.getForAssignment$(assignment.id, serviceRequestOptions, searchParameters),
            )
        ).data;

        if (assignment.project?.id || assignment.parentAssignment?.project?.id) {
            const projectId = assignment.project?.id || assignment.parentAssignment?.project?.id;
            const projectJournals = (
                await firstValueFrom(this.journalApi.getForProject$(projectId, serviceRequestOptions, searchParameters))
            ).data;
            journals.push(...projectJournals);
        }

        this.attachments = journals.map((x) => x.attachments).flat();
    }

    //endregion
}
