import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoaderIconComponent } from "@maas/components/loader-icon/loader-icon.component";
import { StepIndexDirective } from "@maas/components/stepper/step-index.directive";
import { StepperComponent } from "@maas/components/stepper/stepper.component";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { DefaultComponent } from "@maas/layout/content/default/default.component";
import { AssignmentService } from "@maas/services/assignment.service";
import { LocationService } from "@maas/services/location.service";
import { ParkingBanService } from "@maas/services/parking-ban.service";
import { TranslateModule } from "@ngx-translate/core";
import { IParkingBan } from "@ramudden/data-access/models/parking-ban";
import moment from "moment";

@Component({
    selector: "app-assignment-parking-bans",
    standalone: true,
    imports: [
        CommonModule,
        StepperComponent,
        StepIndexDirective,
        NgOptimizedImage,
        SvgComponent,
        DefaultComponent,
        TranslateModule,
        LoaderIconComponent,
    ],
    templateUrl: "./assignment-parking-bans.component.html",
    styleUrl: "./assignment-parking-bans.component.scss",
})
export class AssignmentParkingBansComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly locationService = inject(LocationService);
    private readonly parkingBanService = inject(ParkingBanService);
    private readonly router = inject(Router);

    parkingBans: IParkingBan[] = [];
    isLoading = false;

    get siteActive() {
        const plannedAssignment = this.assignmentService.selectedPlannedAssignment;
        return (
            plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            plannedAssignment.currentTeamStatus?.teamDeparture === undefined
        );
    }

    numberPlates(parkingBan: IParkingBan): string {
        return parkingBan.exceptions.map((e) => e.licensePlate).join(", ");
    }

    address(parkingBan: IParkingBan): string {
        const address = parkingBan.location.address;
        if (address) {
            return this.locationService.convertToAddressString(address);
        }
        return "";
    }

    async ngOnInit() {
        this.parkingBans = await this.fetchParkingBans();
    }

    //region Actions
    async onRefresh() {
        this.parkingBans = await this.fetchParkingBans();
    }

    createParkingBan() {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", "create"]);
    }

    editParkingBan(parkingBan: IParkingBan) {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", parkingBan.id]);
    }

    deleteParkingBan(parkingBan: IParkingBan) {
        const answer = confirm("Are you sure you want to delete this parking ban?");
        if (!answer) {
            return;
        }
        this.isLoading = true;
        this.parkingBanService.deleteParkingBan(parkingBan.id).then(() => this.onRefresh());
    }

    //region Fetch data
    private async fetchParkingBans(): Promise<IParkingBan[]> {
        this.isLoading = true;
        const assignmentId = this.assignmentService.selectedPlannedAssignment.assignmentId;
        if (assignmentId <= 0) return Promise.resolve([] as IParkingBan[]);

        const parkingBans = await this.parkingBanService.getAllParkingBans(assignmentId);
        this.isLoading = false;
        return parkingBans.sort((a, b) => {
            const aTime: number = moment(a.name, "YYYYMMDD_HHmmss").toDate().getTime();
            const bTime: number = moment(b.name, "YYYYMMDD_HHmmss").toDate().getTime();
            return bTime - aTime;
        });
    }

    //endregion
}
