<content-default>
    <ng-container body>
        @if (isLoading) {
            <m-loader-icon />
        }

        @if (parkingBanForm) {
            <form
                class="flex flex-column gap-16"
                [formGroup]="parkingBanForm"
            >
                <!--region Header-->
                <div class="flex align-items-center justify-content-between gap-16">
                    <h3>{{ parkingBan.name }}</h3>

                    <div class="flex align-items-center gap-8">
                        <label for="isCompleted">{{ "parkingBanPage.isCompleted" | translate }}</label>

                        <label class="input--toggle">
                            <input
                                id="isCompleted"
                                formControlName="isCompleted"
                                type="checkbox"
                            />
                            <span></span>
                        </label>
                    </div>
                </div>
                <!--endregion-->

                <!--region General Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.general" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="generalForm"
                        >
                            <!--region Address-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="description"
                                >
                                    {{ "parkingBanPage.address" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        (click)="openMapsDialog($event)"
                                        readonly
                                        value="{{ location.address.line1 }}, {{ location.address.zipCode }} {{
                                            location.address.city
                                        }}"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Description-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="description"
                                >
                                    {{ "parkingBanPage.description" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="description"
                                        formControlName="description"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Start-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="start"
                                >
                                    {{ "parkingBanPage.start" | translate }}
                                </label>

                                <div
                                    class="grid__item-8"
                                    (click)="startDateInput.showPicker()"
                                >
                                    <input
                                        class="input--text"
                                        id="start"
                                        #startDateInput
                                        formControlName="start"
                                        max="{{ parkingBanForm.get('generalForm.end').value | date: 'yyyy-MM-dd' }}"
                                        min="{{ now() | date: 'yyyy-MM-dd' }}"
                                        placeholder=""
                                        type="date"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region End-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="end"
                                >
                                    {{ "parkingBanPage.end" | translate }}
                                </label>

                                <div
                                    class="grid__item-8"
                                    (click)="endDateInput.showPicker()"
                                >
                                    <input
                                        class="input--text"
                                        id="end"
                                        #endDateInput
                                        formControlName="end"
                                        min="{{ parkingBanForm.get('generalForm.start').value | date: 'yyyy-MM-dd' }}"
                                        placeholder=""
                                        type="date"
                                    />
                                </div>
                            </div>
                            <!--endregion-->
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Attachment Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.images" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="photosModal.open($event)"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="attachmentsForm"
                        >
                            @for (photoForm of attachments.controls; track $index) {
                                <ng-container [formGroup]="photoForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onParkingBanAttachmentDelete(photoForm.get('attachmentId').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>

                                        <img
                                            class="attachment__image"
                                            [alt]="photoForm.get('attachmentDescription').value"
                                            [src]="photoForm.get('attachmentImage').value"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="attachmentDescription">{{
                                                    "parkingBanPage.description" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="attachmentDescription"
                                                    formControlName="attachmentDescription"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Exceptions Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.exceptions" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="exceptionsModal.open($event)"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="exceptionsForm"
                        >
                            @for (exceptionForm of exceptions.controls; track $index) {
                                <ng-container [formGroup]="exceptionForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onExceptionDelete(exceptionForm.get('exceptionId').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>

                                        <img
                                            class="attachment__image"
                                            [alt]="exceptionForm.get('exceptionLicensePlate').value"
                                            [src]="exceptionForm.get('exceptionImage').value"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="exceptionLicensePlate">{{
                                                    "parkingBanPage.licensePlate" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="exceptionLicensePlate"
                                                    formControlName="exceptionLicensePlate"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Sign scan Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.scannedSigns" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="signScansModal.open($event)"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="signScansForm"
                        >
                            @for (signScanForm of signScans.controls; track $index) {
                                <ng-container [formGroup]="signScanForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onQrCodeDelete(signScanForm.get('signScanId').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>
                                        <img
                                            class="attachment__image"
                                            [alt]="signScanForm.get('signScanCode').value"
                                            src="/assets/parking-ban.png"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="signScanCode">{{
                                                    "parkingBanPage.code" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="signScanCode"
                                                    formControlName="signScanCode"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </form>
        }
    </ng-container>
</content-default>

<!--region Photo modals-->
<app-photo
    #photosModal
    (snapshotSubmit)="onParkingBanAttachmentSubmit($event)"
/>
<app-photo
    #exceptionsModal
    (snapshotSubmit)="onExceptionSubmit($event)"
/>
<app-qr-scanner
    #signScansModal
    (qrCodeScanned)="onQrCodeScanned($event)"
/>
<!--endregion-->

<!--region Maps dialog-->
@if (this.parkingBanForm) {
    <maps-dialog
        [coordinates]="location?.coordinate"
        (saveModal)="onSaveModal($event)"
    />
}
<!--endregion-->
