import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "@maas/components/modal/modal.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

@Component({
    selector: "pdf-viewer-dialog",
    standalone: true,
    templateUrl: "./pdf-viewer.dialog.html",
    styleUrl: "./pdf-viewer.dialog.scss",
    imports: [ModalComponent, NgxExtendedPdfViewerModule],
})
export class PdfViewerDialog {
    @ViewChild("modal") modal: ModalComponent;

    title: string;
    pdfUrl: string;
    zoom: number = 1.0;

    openModal(title: string, pdfUrl: string, event: Event) {
        this.title = title;
        this.pdfUrl = pdfUrl;

        this.modal.openModal(event);
    }
}
