import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
    selector: "[stepIndex]",
    standalone: true,
})
export class StepIndexDirective {
    constructor(public templateRef: TemplateRef<any>) {}

    private _stepIndex!: number;
    @Input("stepIndex") set stepIndex(value: number | string) {
        this._stepIndex = typeof value === "string" ? Number(value) : value;
    }
    get stepIndex(): number {
        return this._stepIndex;
    }
}
