<div
    class="card--task"
    [style.--status-color]="getStatusColor()"
>
    <div class="card__header flex justify-content-between align-items-center">
        <div class="flex align-items-center gap-16">
            <h2>{{ task().name }}</h2>

            @if (task()?.currentStatus?.isMachineWork) {
                <m-svg
                    iconName="bulldozer"
                    style="--icon-width: 2.4rem; --icon-height: 2.4rem; --icon-fill: var(--color-neutral500)"
                />
            }
        </div>

        <div class="flex align-items-center gap-8">
            <button
                class="btn--icon"
                (click)="navigateToTaskEdit()"
            >
                <m-svg iconName="edit" />
            </button>
            <button
                class="btn--icon"
                (click)="copyTask()"
            >
                <m-svg iconName="copy" />
            </button>
            <button
                class="btn--icon"
                (click)="deleteTask()"
            >
                <m-svg iconName="delete" />
            </button>
        </div>
    </div>

    <div class="card__body">
        <div class="grid">
            <div class="grid__item-4">
                <dl class="data-list">
                    <dt>{{ "taskPage.type" | translate }}</dt>
                    <dd>{{ task().currentStatus.taskTypeId | translateEnum: "taskType" }}</dd>
                    <dt>{{ "taskPage.mainCategory" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.signCategoryId | translateEnum: "signCategoryValue" }}
                    </dd>
                    <dt>{{ "taskPage.address" | translate }}</dt>
                    <dd>
                        {{ getAddress() }}
                    </dd>
                    <dt>{{ "taskPage.count" | translate }}</dt>
                    <dd>TODO</dd>
                </dl>
            </div>
            <div class="grid__item-4">
                <dl class="data-list">
                    <dt>{{ "taskPage.material" | translate }}</dt>
                    <dd>{{ task().currentStatus.signMaterialId | translateEnum: "signMaterial" }}</dd>
                    <dt>{{ "taskPage.start" | translate }}</dt>
                    <dd>{{ task().start }}</dd>
                </dl>
            </div>
            <div class="grid__item-4">
                <dl class="data-list">
                    <dt>{{ "taskPage.isNew" | translate }}</dt>
                    <dd>{{ (task().currentStatus.isNew ? "taskPage.yes" : "taskPage.no") | translate }}</dd>
                </dl>
            </div>
        </div>
    </div>
</div>
