<m-modal
    [title]="title"
    #modal
>
    <ng-container body>
        <ngx-extended-pdf-viewer
            [showBookModeButton]="false"
            [showBorders]="false"
            [showSidebarButton]="false"
            [showToolbar]="false"
            [sidebarVisible]="false"
            [src]="pdfUrl"
            [zoom]="'auto'"
            style="width: 100%; height: 100%"
        />
    </ng-container>
</m-modal>
