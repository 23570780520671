import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { ToastService } from "@maas/components/toast/toast.service";
import { AppService } from "@maas/services/app.service";
import { TitleService } from "@maas/services/title.service";

@Component({
    selector: "page-header",
    standalone: true,
    imports: [SvgComponent, RouterLink],
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly appService = inject(AppService);
    protected readonly titleService = inject(TitleService);
    private readonly router = inject(Router);
    private readonly toastService = inject(ToastService);

    public refreshClicked() {
        this.toastService.showInfo("Refreshing data...");
        this.titleService.refreshClicked$.next();
    }

    public userIconClick() {
        return this.router.navigate(["/setup"]);
    }

    public endWorkday() {}
}
