import { CommonModule } from "@angular/common";
import { Component, input, OnInit } from "@angular/core";
import { environment } from "@maas-env/environment";

export type IconName =
    | "back"
    | "circle-check"
    | "circle-plus"
    | "close"
    | "copy"
    | "delete"
    | "edit"
    | "file-pdf"
    | "leader"
    | "marker-down"
    | "markings"
    | "parking"
    | "plus"
    | "reload"
    | "signs"
    | "star"
    | "task"
    | "user"
    | "vehicle"
    | "wjet"
    | "worker";

@Component({
    selector: "m-svg",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./svg.component.html",
    styleUrl: "./svg.component.scss",
})
export class SvgComponent implements OnInit {
    protected href: string;

    iconName = input.required<IconName>();
    toolTip = input<string>();

    ngOnInit() {
        this.setHref();
    }

    setHref() {
        this.href = `icons.svg?v=${environment.svgVersion}#${this.iconName()}`;
    }
}
