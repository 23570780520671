export {};
import moment from "moment";

declare module "moment" {
    interface Moment {
        mFormatDate(): string;
    }
}

moment.fn.mFormatDate = function () {
    return this.format("YYYY/MM/DD");
};
