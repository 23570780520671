import "@ramudden/core/extensions";

import { ApplicationRef, enableProdMode } from "@angular/core";
import { bootstrapApplication, enableDebugTools } from "@angular/platform-browser";
import { environment } from "@maas-env/environment";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .then((moduleRef) => {
        if (!environment.production) {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];

            // allows to run `ng.profiler.timeChangeDetection();`
            enableDebugTools(componentRef);
        }
    })
    .catch((err) => console.error(err));
