import { Injectable, isDevMode } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

export interface IComponentCanDeactivate {
    canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable({ providedIn: "root" })
export class PendingChangesGuard implements CanDeactivate<IComponentCanDeactivate> {
    canDeactivate(
        component: IComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot,
    ): boolean | Promise<boolean> | Observable<boolean> {
        if (!component) {
            if (isDevMode()) {
                console.warn(
                    "PendingChangesGuard -> Component was null.",
                    { route: currentRoute, state: currentState },
                    nextState,
                );
            }

            return true;
        }

        return component.canDeactivate();
    }
}
