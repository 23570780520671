<content-default>
    <ng-container body>
        @if (isLoading) {
            <m-loader-icon />
        }

        <h2 class="mb-24">{{ "taskPage.tasks" | translate }}</h2>

        <div class="flex flex-column gap-16">
            @for (task of tasks; track task.id) {
                <app-task-card
                    [task]="task"
                    (refreshTasks)="onRefreshTasks()"
                />
            }
        </div>
    </ng-container>
    <ng-container floating-action>
        @if (siteActive) {
            <button
                class="btn--floating-action"
                (click)="createTask()"
                title="{{ 'taskPage.createTask' | translate }}"
            >
                <m-svg iconName="plus" />
            </button>
        }
    </ng-container>
</content-default>
