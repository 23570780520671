import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { NavigationComponent } from "@maas/components/navigation/navigation.component";
import { AssignmentService } from "@maas/services/assignment.service";
import { TitleService } from "@maas/services/title.service";

@Component({
    selector: "app-assignment-container",
    standalone: true,
    imports: [CommonModule, RouterOutlet, NavigationComponent],
    templateUrl: "./assignment-container.component.html",
    styleUrl: "./assignment-container.component.scss",
})
export class AssignmentContainerComponent implements OnInit {
    protected readonly assignmentsService = inject(AssignmentService);
    private readonly titleService = inject(TitleService);
    private readonly router = inject(Router);

    ngOnInit() {
        const plannedAssignment = this.assignmentsService?.selectedPlannedAssignment;
        this.titleService.goBack.set(["/assignments"]);
        this.titleService.title = plannedAssignment?.assignment?.parentAssignment
            ? `${plannedAssignment?.assignment?.parentAssignment.name} - ${plannedAssignment?.assignment?.name}`
            : plannedAssignment?.assignment?.name;
    }

    getPlannedAssignmentId(): number {
        if (!this.assignmentsService?.selectedPlannedAssignment?.id) {
            this.router.navigate(["/assignments"]);
            return;
        }
        return this.assignmentsService?.selectedPlannedAssignment?.id;
    }
}
