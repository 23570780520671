import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";

import { IListResult, SearchParameters } from "@ramudden/data-access/models/search";
import {
    IGroupSummary,
    ILocationStatusSummary,
    ILocationSummary,
    ILocationWithAssignmentsSummary,
    ILocationWithDevicesSummary,
    MeasuringPointSearchParameters,
} from "@ramudden/data-access/models/web";
import { CacheOptions } from "../api";
import { WebApiService } from "./web-api";

@Injectable({
    providedIn: "root",
})
export class LocationWebApi extends WebApiService<ILocationSummary> {
    override getRoute(): string {
        return "Locations";
    }

    getMeasuringPoints$(
        cacheOptions: CacheOptions = null,
        searchParameters: SearchParameters = null,
    ): Observable<IListResult<ILocationSummary>> {
        const url = this.getUrl() + "/LocationsWithMeasuringPoints" + (searchParameters ? "/Untyped" : "");

        const options = this.createOptions(null, searchParameters, null);

        if (cacheOptions) {
            return this.handleCaching(url, options, cacheOptions);
        }

        return this.http.get<IListResult<ILocationSummary>>(url, options);
    }

    async getLocationsWithMeasuringPoints(
        searchParameters?: MeasuringPointSearchParameters,
    ): Promise<IListResult<ILocationSummary>> {
        const url = this.getUrl() + "/LocationsWithMeasuringPoints";
        const params = searchParameters?.getHttpParams();
        const result = await firstValueFrom(this.http.get<IListResult<ILocationSummary>>(url, { params: params }));
        return result;
    }

    getDevices$(
        cacheOptions: CacheOptions = null,
        searchParameters: SearchParameters = null,
    ): Observable<IListResult<ILocationWithDevicesSummary>> {
        const url = this.getUrl() + "/LocationsWithDevices" + (searchParameters ? "/Untyped" : "");
        const options = this.createOptions(null, searchParameters, null);

        if (cacheOptions) {
            return this.handleCaching(url, options, cacheOptions);
        }

        return this.http.get<IListResult<ILocationWithDevicesSummary>>(url, options);
    }

    getGroups$(
        cacheOptions: CacheOptions = null,
        searchParameters: SearchParameters = null,
    ): Observable<IListResult<IGroupSummary>> {
        const url = this.getUrl() + "/LocationsWithGroups" + (searchParameters ? "/Untyped" : "");
        const options = this.createOptions(null, searchParameters, null);

        if (cacheOptions) {
            return this.handleCaching(url, options, cacheOptions);
        }

        return this.http.get<IListResult<IGroupSummary>>(url, options);
    }

    getAssignments$(
        cacheOptions: CacheOptions = null,
        searchParameters: SearchParameters = null,
    ): Observable<IListResult<ILocationWithAssignmentsSummary>> {
        const url = this.getUrl() + "/LocationsWithAssignments" + (searchParameters ? "/Untyped" : "");
        const options = this.createOptions(null, searchParameters, null);

        if (cacheOptions) {
            return this.handleCaching(url, options, cacheOptions);
        }

        return this.http.get<IListResult<ILocationWithAssignmentsSummary>>(url, options);
    }

    public async getLocationStatusSummary(id: number): Promise<ILocationStatusSummary> {
        const url = this.getUrl() + "/" + id + "/StatusSummary";
        const result = await firstValueFrom(this.http.get<ILocationStatusSummary>(url));
        return result;
    }
}
