import { Injectable } from "@angular/core";
import {
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater,
} from "@ramudden/data-access/models/parking-ban-exception";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ParkingBanExceptionApi extends ApiService<
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater
> {
    override getRoute(): string {
        return "ParkingBanExceptions";
    }
}
