import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { AssignmentService } from "@maas/services/assignment.service";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { ISignScan } from "@ramudden/data-access/models/sign-scan";
import { SignScanApi } from "@ramudden/data-access/resource/sign-scan.api";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-assignment-signs",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./assignment-signs.component.html",
    styleUrl: "./assignment-signs.component.scss",
})
export class AssignmentSignsComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly signScanApi = inject(SignScanApi);

    signScans: ISignScan[] = [];

    async ngOnInit() {
        this.signScans = await this.fetchSigns();
    }

    private async fetchSigns(): Promise<ISignScan[]> {
        const assignmentId = this.assignmentService.selectedPlannedAssignment.assignmentId;
        if (assignmentId <= 0) return Promise.resolve([] as ISignScan[]);

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("SignScan", "Location");

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals)];

        return await firstValueFrom(this.signScanApi.getAll$(searchParameters, null, serviceRequestOptions));
    }
}
