<m-modal
    #modal
    title="Neem foto"
>
    <ng-container body>
        <div
            class="rectangle"
            style="--rectangle-aspect-ratio: 3 / 4"
        >
            <video
                #video
                autoplay
            ></video>

            <canvas
                #canvas
                style="display: none"
            ></canvas>
        </div>
    </ng-container>

    <ng-container footer>
        @if (!webcamImage) {
            <div class="flex justify-content-end">
                <button
                    class="btn--yellow"
                    (click)="triggerSnapshot()"
                >
                    Foto nemen
                    <m-svg iconName="camera" />
                </button>
            </div>
        } @else {
            <div class="flex justify-content-between">
                <button
                    class="btn"
                    (click)="retakeSnapshot()"
                >
                    Nieuwe foto nemen
                    <m-svg iconName="camera" />
                </button>
                <button
                    class="btn--yellow"
                    (click)="submitSnapshot()"
                >
                    Toevoegen
                    <m-svg iconName="plus" />
                </button>
            </div>
        }
    </ng-container>
</m-modal>
