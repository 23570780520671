import { Injectable } from "@angular/core";
import { IJournal, JournalCreator } from "@ramudden/data-access/models/journal";
import { ISearchResult, SearchParameters, ServiceRequestOptions } from "@ramudden/data-access/models/search";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class JournalApi extends ApiService<IJournal, JournalCreator, JournalCreator> {
    override getRoute() {
        return "Journal/{?type}/{?id}";
    }

    getForAssignment$(
        assignmentId: number,
        options?: ServiceRequestOptions,
        searchParameters?: SearchParameters,
    ): Observable<ISearchResult<IJournal>> {
        const url = `${super.getUrl()}/Assignment/${assignmentId}`;
        const httpOptions = this.createOptions(options, searchParameters, null);

        return this.http.get<ISearchResult<IJournal>>(url, httpOptions);
    }

    getForProject$(
        assignmentId: number,
        options?: ServiceRequestOptions,
        searchParameters?: SearchParameters,
    ): Observable<ISearchResult<IJournal>> {
        const url = `${super.getUrl()}/Project/${assignmentId}`;
        const httpOptions = this.createOptions(options, searchParameters, null);

        return this.http.get<ISearchResult<IJournal>>(url, httpOptions);
    }
}
