import { Injectable } from "@angular/core";
import { IPlannedVehicle } from "@ramudden/data-access/models/planned-event";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class PlannedVehiclesApi extends ApiService<IPlannedVehicle, null, null> {
    override getRoute(): string {
        return "PlannedVehicles";
    }
}
