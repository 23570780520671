<page-default>
    <ng-container header>
        <page-header></page-header>
    </ng-container>

    <ng-container body>
        @if (!isIframe) {
            <router-outlet></router-outlet>
        }
    </ng-container>
</page-default>

<m-toast />
