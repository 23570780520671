import { Injectable } from "@angular/core";
import { AnalysisType } from "@ramudden/data-access/models/measuring-point";
import { ExistsResult, ExistsValue } from "@ramudden/data-access/models/search";
import { IOrganization, OrganizationCreator } from "@ramudden/data-access/models/user";
import { map, Observable } from "rxjs";
import { ApiService } from "./api";

export enum AvailableReports {
    "Assignment_Overview" = "assignmentOverview",
    "Device_Overview" = "deviceOverview",
    "Distance_Evolution" = "distanceEvolution",
    "Distance_Profile" = "distanceProfile",
    "Expense_Overview" = "expenseOverview",
    "Location_Overview" = "locationOverview",
    "Map_Overview" = "mapOverview",
    "Measured_Days" = "measuredDays",
    "Measuring_Point_Export" = "measuringPointExport",
    "Occupancy_Evolution" = "occupancyEvolution",
    "Origin_Destination" = "originDestination",
    "Parking" = "parking",
    "Parking_Ban_Overview" = "parkingBanOverview",
    "Parking_Ban_Removal_Overview" = "parkingBanRemovalOverview",
    "Prediction" = "prediction",
    "Project_Details" = "projectDetails",
    "Project_Overview" = "projectOverview",
    "Projects_Per_Organization" = "projectsPerOrganization",
    "Scenario_Activity_Log" = "scenarioActivityLog",
    "Speed_Evolution" = "speedEvolution",
    "Speed_Profile" = "speedProfile",
    "Vehicle_Categories" = "vehicleCategories",
    "Vehicle_Details" = "vehicleDetails",
    "Vehicle_Groups" = "vehicleGroups",
    "Vehicles_Per_Hour" = "vehiclesPerHour",
    "XPos" = "xPos",
    "Year_Overview" = "yearOverview",
    "Installation_Overview" = "installationOverview",
}

@Injectable({
    providedIn: "root",
})
export class OrganizationApi extends ApiService<IOrganization, OrganizationCreator, IOrganization> {
    override getRoute(): string {
        return "Organizations";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;
        return this.http.get<ExistsResult>(url, {
            params: { code: existsValue.value, parentOrganizationId: existsValue.parentOrganizationId.toString() },
        });
    }

    uploadLogo$(organization: IOrganization, file: File): Observable<IOrganization> {
        const url = `${super.getUrl()}/${organization.id}/UploadLogo`;
        const formData = new FormData();

        if (file) {
            formData.append("icon", file);
        }

        return this.http.post<IOrganization>(url, formData);
    }

    getChildren$(organizationId: number): Observable<IOrganization[]> {
        const url = `${super.getUrl()}/${organizationId}/children`;
        return this.http.get<IOrganization[]>(url);
    }

    getAllowedAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/AllowedAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    getInUseAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/InUseAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    addAnalysisTypes$(organizationId: number, analysisTypes: AnalysisType[]): Observable<void> {
        const url = `${super.getUrl()}/${organizationId}/AddAnalysisTypes`;
        return this.http.post<void>(url, analysisTypes);
    }

    getAvailableReports$(organizationId: number): Observable<string[]> {
        const url = `${super.getUrl()}/${organizationId}/ReportTypes`;
        return this.http
            .get<AvailableReports[]>(url)
            .pipe(map((reports) => reports.map((report) => report.toCamelCase())));
    }

    changeAvailableReports(organizationId: number, reports: string[]): Observable<void> {
        const url = `${super.getUrl()}/${organizationId}/ReportTypes`;
        return this.http.post<void>(url, reports);
    }
}
