export class Constants {
    static readonly callbackRoute = "CallbackUrl";
    static readonly mapSplitSize = "MapSplitSize";
    static readonly planningSplitSize = "PlanningSplitSize";
    static readonly mapState = "MapState";
    static readonly validationStorageKey = "validationContext";
    static readonly organizationImpersonationStorageKey = "organizationImpersonation";
    static readonly roleImpersonationStorageKey = "roleImpersonation";
    static readonly cookieNotificationStorageKey = "acceptedCookies";

    static readonly cacheDurationInMinutes = 30;

    static readonly markerLabelColorDefault = "#000";
    static readonly markerTextColorLight = "#333";
    static readonly markerTextColorDark = "#FFF";

    static readonly markerArrowIcon =
        "M16,10.2V7.9L18.6,9l0.1-0.1l-2.3-4.2c-0.5-1.5-0.9-2.9-1.4-4.3c-0.5,1.5-0.9,2.9-1.4,4.3l-2.3,4.2L11.4,9L14,7.9v2.3	c-2.3,0.5-4,2.5-4,4.9s1.7,4.4,4,4.9v9.8h2v-9.8c2.3-0.5,4-2.5,4-4.9S18.3,10.7,16,10.2z M16,18.9C15.7,19,15.3,19,15,19	s-0.7-0.1-1-0.1c-1.7-0.4-3-2-3-3.8c0-1.8,1.3-3.4,3-3.8c0.3-0.1,0.7-0.1,1-0.1s0.7,0.1,1,0.1c1.7,0.4,3,2,3,3.8	C19,16.9,17.7,18.4,16,18.9z";
    static readonly markerArrowIconColor = "orange";

    static readonly noClusterZoom = 12; // The zoom level at which the markers are no longer clustered
    static readonly markerLabelZoom = 16; // The zoom level at which the labels are shown on the markers

    static colorPerVehicleCategory: { [key: string]: string } = {
        light: "rgba(169, 208, 142, 1.0)",
        medium: "rgba(255, 217, 102, 1.0)",
        heavy: "rgba(244, 176, 132, 1.0)",
        bike: "rgba(0, 255, 204, 1.0)",
        moped: "rgba(255, 153, 255, 1.0)",
        pedestrian: "rgba(65, 105, 225, 1.0)",
        unknown: "rgba(128, 128, 128, 1.0)",
    };

    public static readonly colorAlertInfo = "#FFE137";
    public static readonly colorAlertWarning = "#FF7A22";
    public static readonly colorAlertError = "#F14141";

    public static getAlertColor(level: string): string {
        switch (level) {
            case "error":
                return Constants.colorAlertError;
            case "warning":
                return Constants.colorAlertWarning;
            case "info":
                return Constants.colorAlertInfo;
            default:
                throw new Error("Unknown alert level");
        }
    }
}
