import { NgTemplateOutlet } from "@angular/common";
import { AfterContentInit, Component, ContentChildren, input, output, QueryList, TemplateRef } from "@angular/core";
import { StepIndexDirective } from "@maas/components/stepper/step-index.directive";

@Component({
    selector: "m-stepper",
    templateUrl: "./stepper.component.html",
    styleUrls: ["./stepper.component.scss"],
    standalone: true,
    imports: [NgTemplateOutlet],
})
export class StepperComponent implements AfterContentInit {
    withoutControls = input<boolean>(false);
    steps = input.required<string[]>();
    stepChanged = output<number>();
    finished = output<void>();

    @ContentChildren(StepIndexDirective) stepTemplates!: QueryList<StepIndexDirective>;

    public currentStepIndex: number = 0;
    private templatesMap: Map<number, TemplateRef<any>> = new Map();

    get isFirstStep(): boolean {
        return this.currentStepIndex === 0;
    }

    get isLastStep(): boolean {
        return this.currentStepIndex === this.steps().length - 1;
    }

    ngAfterContentInit(): void {
        if (this.steps().length < 2 || this.steps().length > 6) {
            throw new Error("Steps must be between 2 and 6 items long.");
        }

        this.stepTemplates.forEach((stepTemplate) => {
            this.templatesMap.set(stepTemplate.stepIndex, stepTemplate.templateRef);
        });
    }

    public nextStep(): void {
        if (this.currentStepIndex < this.steps().length - 1) {
            this.currentStepIndex++;
            this.stepChanged.emit(this.currentStepIndex);
        }
    }

    public previousStep(): void {
        if (this.currentStepIndex > 0) {
            this.currentStepIndex--;
            this.stepChanged.emit(this.currentStepIndex);
        }
    }

    public finish(): void {
        this.finished.emit();
    }

    get currentStepTemplate(): TemplateRef<any> | null {
        return this.templatesMap.get(this.currentStepIndex) || null;
    }
}
