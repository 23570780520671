import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { SvgComponent } from "@maas/components/svg/svg.component";

@Component({
    selector: "page-navigation",
    standalone: true,
    imports: [RouterLink, RouterOutlet, RouterLinkActive, SvgComponent],
    templateUrl: "./navigation.component.html",
    styleUrl: "./navigation.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
    plannedAssignmentId = input.required<number>();
}
