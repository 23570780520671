<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
    class="card--task"
    [style.--status-color]="getStatusColor()"
    (click)="assignmentClicked.emit(plannedAssignment())"
>
    <div class="card__header">
        <h3>{{ name() }}</h3>

        <div class="align-self-start flex align-items-center gap-16">
            <span class="badge--task">
                {{ assignment().currentStatus.statusId | translateEnum: "assignmentStatus" }}
            </span>

            <a
                class="btn--google-maps"
                [href]="getGoogleMapsLink()"
                (click)="$event.stopPropagation()"
                target="_blank"
            >
                <img
                    alt="Google Maps"
                    height="24"
                    ngSrc="/assets/google_maps.svg"
                    width="24"
                />
            </a>
        </div>
    </div>

    <div class="card__body">
        <div class="grid">
            <div class="grid__item-6">
                <dl class="data-list">
                    <dt>{{ "assignmentsPage.date" | translate }}</dt>
                    <dd>{{ plannedAssignment()?.plannedEvent?.eventDate | date }}</dd>
                    <dt>{{ "assignmentsPage.description" | translate }}</dt>
                    <dd>{{ description() }}</dd>
                    <dt>{{ "assignmentsPage.type" | translate }}</dt>
                    <dd>
                        {{ assignment().taskTypeId | translateEnum: "taskType" ?? "/" }}
                    </dd>
                    <dt>{{ "assignmentsPage.material" | translate }}</dt>
                    <dd>
                        {{ assignment().signMaterialId | translateEnum: "signMaterial" ?? "/" }}
                    </dd>
                </dl>
            </div>
            <div class="grid__item-6">
                <dl class="data-list">
                    <dt>{{ "assignmentsPage.organisation" | translate }}</dt>
                    <dd>
                        {{ organization() }}
                    </dd>
                    <dt>{{ "assignmentsPage.address" | translate }}</dt>
                    <dd>{{ address() }}</dd>
                    <dt>{{ "assignmentsPage.isMachineWork" | translate }}</dt>
                    <dd>
                        @if (isMachineWork()) {
                            <m-svg
                                iconName="circle-check"
                                style="--icon-width: 2rem; --icon-height: 2rem; --icon-fill: var(--color-success)"
                            />
                        } @else {
                            <m-svg
                                iconName="circle-xmark"
                                style="--icon-width: 2rem; --icon-height: 2rem; --icon-fill: var(--color-error)"
                            />
                        }
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</div>
