import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomainDataService } from "@ramudden/services";
import { Subscription } from "rxjs";

@Pipe({ name: "translateEnum", pure: false, standalone: true })
export class TranslateEnumPipe implements PipeTransform, OnDestroy {
    lastDomain: string | null = null;
    lastKey: string | null = null;
    lastFormatParameters: string[] | null = null;
    onLangChange: Subscription | undefined;
    value = "";

    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly domainDataService = inject(DomainDataService);
    private readonly translateService = inject(TranslateService);

    updateValue(key: string, domain: string, formatParameters?: string[]): void {
        const result = this.domainDataService.translateEnum(domain, key, formatParameters);
        if (result) {
            this.value = result;
            this.lastKey = key;
            this.lastFormatParameters = formatParameters;
            this.changeDetectorRef.markForCheck();
        }
    }

    transform(value: string, domain: string, formatParameters?: string[]): string {
        if (value === this.lastKey && domain === this.lastDomain && formatParameters === this.lastFormatParameters) {
            return this.value;
        }

        this.lastDomain = domain;
        this.lastKey = value;
        this.lastFormatParameters = formatParameters;
        this.updateValue(value, domain, formatParameters);
        this.dispose();

        if (!this.onLangChange) {
            this.onLangChange = this.translateService.onLangChange.subscribe(() => {
                if (this.lastKey) {
                    this.lastKey = null;
                    this.updateValue(value, domain);
                }
            });
        }

        return this.value;
    }

    private dispose() {
        if (this.onLangChange) {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
    }

    ngOnDestroy() {
        this.dispose();
    }
}
