import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { DropdownComponent } from "@maas/components/dropdown/dropdown.component";
import { DefaultComponent } from "@maas/layout/content/default/default.component";
import { TeamDialog, TeamDialogOutput } from "@maas/pages/setup/dialogs/team/team.dialog";
import { UserDialog, UserDialogOutput } from "@maas/pages/setup/dialogs/user/user.dialog";
import { ISelectedVehicle, VehicleDialog } from "@maas/pages/setup/dialogs/vehicle/vehicle.dialog";
import { AppService } from "@maas/services/app.service";
import {
    IPlannedEvent,
    IPlannedVehicle,
    IPlannedWorker,
    PlannedEventUpdater,
} from "@ramudden/data-access/models/planned-event";
import { ServiceRequestOptions } from "@ramudden/data-access/models/search";
import { PlannedEventApi } from "@ramudden/data-access/resource/planned-event-api";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-setup",
    standalone: true,
    imports: [
        CommonModule,
        DefaultComponent,
        DropdownComponent,
        ReactiveFormsModule,
        UserDialog,
        TeamDialog,
        VehicleDialog,
    ],
    templateUrl: "./setup.component.html",
})
export class SetupComponent implements OnInit {
    @ViewChild("userDialog", { static: true }) userDialog: UserDialog;
    @ViewChild("teamDialog", { static: true }) teamDialog: TeamDialog;
    @ViewChild("vehicleDialog", { static: true }) vehicleDialog: VehicleDialog;

    private readonly appService = inject(AppService);
    private readonly plannedEventApi = inject(PlannedEventApi);
    private readonly router = inject(Router);

    splitTeam: boolean;

    ngOnInit() {
        this.appService.restoreState();

        if (this.appService.config?.isConfigured) {
            this.teamDialog.openModal();
            return;
        }
        this.userDialog.openModal();
        return;
    }

    onUserDialogClosed(userDialogOutput: UserDialogOutput) {
        this.appService.config = {
            language: userDialogOutput.language,
            isConfigured: false,
            plannedEventId: undefined,
        };
        this.appService.selectedWorker = {
            firstName: userDialogOutput.worker.firstName,
            id: userDialogOutput.worker.id,
            isActive: true,
            isTeamLeader: true,
            lastName: userDialogOutput.worker.lastName,
            organizationId: userDialogOutput.worker.organizationId,
            canDoControl: userDialogOutput.worker.canDoControl,
        };
        this.appService.plannedEvent = userDialogOutput.plannedEvent;

        this.teamDialog.openModal();
    }

    onTeamDialogClosed(teamDialogOutput: TeamDialogOutput) {
        this.appService.selectedWorkers = teamDialogOutput.selectedWorkers;
        this.splitTeam = teamDialogOutput.splitTeam;

        this.vehicleDialog.openModal();
    }

    onVehicleDialogClosed(selectedVehicles: ISelectedVehicle[]) {
        this.appService.selectedVehicles = selectedVehicles;
        this.saveConfiguration();
    }

    //region Save configuration

    async saveConfiguration() {
        if (this.appService.selectedWorker === undefined) {
            this.appService.clearState();
            this.router.navigate(["/setup"]);
            return;
        }

        const plannedEvent = this.appService.plannedEvent;
        const selectedWorkers = this.appService.selectedWorkers;

        let plannedEventUpdater = {
            id: plannedEvent.id,
            eventDate: plannedEvent.eventDate,
            order: plannedEvent.order,
            isNightShift: plannedEvent.isNightShift,
            isWeekendShift: plannedEvent.isWeekendShift,
            plannedWorkers: [],
            plannedVehicles: [],
        } as IPlannedEvent;

        for (let i = 0; i < selectedWorkers.length; i++) {
            let existing = plannedEvent.plannedWorkers.find(
                (x) => x.plannedEventId === plannedEvent.id && x.workerId === selectedWorkers[i].id,
            );

            let plannedWorkCreator = {
                id: existing?.id,
                workerId: selectedWorkers[i].id,
                plannedEventId: plannedEvent.id,
                displayOrder: i,
                isCreatedByMobileApp: existing?.isCreatedByMobileApp ?? true,
                date: plannedEvent.eventDate,
            } as IPlannedWorker;

            plannedEventUpdater.plannedWorkers.push(plannedWorkCreator);
        }

        this.appService.selectedVehicles.forEach((vehicle) => {
            let plannedVehicleCreator = {
                deviceId: vehicle.deviceId,
                plannedEventId: plannedEvent.id,
            } as IPlannedVehicle;

            plannedEventUpdater.plannedVehicles.push(plannedVehicleCreator);
        });

        const plannedEventUpdaterClass = new PlannedEventUpdater(plannedEventUpdater);

        try {
            const promise1 = firstValueFrom(this.plannedEventApi.update$(plannedEventUpdaterClass));
            const promise2 = Promise.resolve();

            await Promise.all([promise1, promise2]).then((results) => {
                this.appService.config = {
                    isConfigured: true,
                    language: this.appService.config.language,
                    plannedEventId: plannedEvent.id,
                };
            });

            const serviceRequestOptions = new ServiceRequestOptions();
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedWorkers");
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedAssignments");
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedVehicles");
            serviceRequestOptions.includes.add("PlannedWorker", "Worker");
            serviceRequestOptions.includes.add("Project", "organization");

            this.appService.plannedEvent = await firstValueFrom(
                this.plannedEventApi.get$(plannedEvent.id, null, serviceRequestOptions),
            );
        } catch (error) {
            alert("An error occurred while saving the configuration");
            // TODO: SIGN OUT
            console.error(error);
        }

        this.appService.saveState();
        this.router.navigate(["/assignments"]);
    }

    //endregion
}
