import { Injectable } from "@angular/core";
import {
    AssignmentCreator,
    AssignmentSubtaskSearchParameters,
    AssignmentUpdater,
    IAssignment,
    IAssignmentSubtask,
} from "@ramudden/data-access/models/assignment";
import { Observable } from "rxjs";
import { ApiService, HttpOptions } from "./api";

@Injectable({
    providedIn: "root",
})
export class AssignmentApi extends ApiService<IAssignment, AssignmentCreator, AssignmentUpdater> {
    override getRoute(): string {
        return "Assignments";
    }

    getSubtasksSummary$(params: AssignmentSubtaskSearchParameters): Observable<IAssignmentSubtask[]> {
        const url = `${super.getUrl()}/subtasks`;
        return this.http.get<IAssignmentSubtask[]>(url, { params: params } as HttpOptions);
    }
}
