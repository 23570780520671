import { Component, inject, input, output } from "@angular/core";
import { Router } from "@angular/router";
import { SvgComponent } from "@maas/components/svg/svg.component";
import { ToastService } from "@maas/components/toast/toast.service";
import { TranslateEnumPipe } from "@maas/pipes/translate-enum.pipe";
import { AssignmentService } from "@maas/services/assignment.service";
import { LocationService } from "@maas/services/location.service";
import { TranslateModule } from "@ngx-translate/core";
import { ITask, TaskCreator, TaskStatus, TaskStatusHistoryCreator } from "@ramudden/data-access/models/task";
import { TaskApi } from "@ramudden/data-access/resource/task.api";
import moment from "moment";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-task-card",
    standalone: true,
    templateUrl: "./task-card.component.html",
    styleUrl: "./task-card.component.scss",
    imports: [SvgComponent, TranslateEnumPipe, TranslateModule],
})
export class TaskCardComponent {
    task = input.required<ITask>();
    refreshTasks = output<void>();

    private readonly assignmentService = inject(AssignmentService);
    private readonly locationService = inject(LocationService);
    private readonly router = inject(Router);
    private readonly taskApi = inject(TaskApi);
    private readonly toastService = inject(ToastService);

    getAddress(): string {
        return this.locationService.convertToAddressString(this.task().location.address);
    }

    async deleteTask() {
        const answer = confirm("Are you sure you want to delete this task?");
        if (!answer) {
            return;
        }
        await firstValueFrom(this.taskApi.delete$(this.task().id));
        this.refreshTasks.emit();
    }

    async copyTask() {
        const taskCreator = new TaskCreator();

        taskCreator.name = moment().format("YYYYMMDD_HHmmss");
        taskCreator.description = this.task().description;
        taskCreator.start = this.task()?.start;
        taskCreator.end = this.task()?.end;
        taskCreator.assignmentId = this.task().assignment.id;
        taskCreator.isRecurring = false;
        taskCreator.locationId = this.task()?.location?.id;
        taskCreator.currentStatus = new TaskStatusHistoryCreator().init(this.task().currentStatus);
        taskCreator.currentStatus.taskStatusId = TaskStatus.InProgress;

        const newTask = await firstValueFrom(this.taskApi.create$(taskCreator));
        this.toastService.showSuccess("Task copied successfully");
        this.router.navigate(["/assignment", this.assignmentService.selectedPlannedAssignment.id, "task", newTask.id]);
    }

    navigateToTaskEdit() {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "task", this.task().id]);
    }

    getStatusColor(): string {
        const statusId = this.task().currentStatus.taskStatusId;

        if (!statusId) {
            return "var(--color-default)";
        }

        switch (statusId) {
            case TaskStatus.Scheduled:
                return "var(--color-not-started)";
            case TaskStatus.Finished:
                return "var(--color-finished)";
            case TaskStatus.InProgress:
                return "var(--color-in-progress)";
            default:
                return "var(--color-default)";
        }
    }
}
