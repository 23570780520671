<div class="stepper">
    <!-- Step Navigation -->
    <div class="stepper__header">
        <div class="steps">
            @for (step of steps(); track step) {
                <div
                    class="step"
                    [class.is-active]="$index === currentStepIndex"
                >
                    <span>{{ step }}</span>
                </div>
            }
        </div>
    </div>

    <!-- Step Content -->
    <div class="stepper__body">
        <div class="container">
            @if (currentStepTemplate) {
                <ng-container *ngTemplateOutlet="currentStepTemplate"></ng-container>
            } @else {
                No template found for step {{ currentStepIndex }}
            }
        </div>
    </div>

    <!-- Controls -->
    @if (!withoutControls()) {
        <div class="stepper__footer">
            <div>
                @if (!isFirstStep) {
                    <button
                        class="btn"
                        (click)="previousStep()"
                    >
                        Previous
                    </button>
                }
            </div>

            <div>
                @if (isLastStep) {
                    <button
                        class="btn--yellow"
                        (click)="finish()"
                    >
                        Finish
                    </button>
                } @else {
                    <button
                        class="btn--yellow"
                        (click)="nextStep()"
                    >
                        Next
                    </button>
                }
            </div>
        </div>
    }
</div>
