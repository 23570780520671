import { Injectable } from "@angular/core";
import { IWorker, WorkerCreator } from "@ramudden/data-access/models/worker";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class WorkerApi extends ApiService<IWorker, WorkerCreator, WorkerCreator> {
    override getRoute(): string {
        return "Workers";
    }
}
