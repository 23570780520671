import { NgClass } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ToastMessage, ToastService } from "./toast.service";

@Component({
    selector: "m-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
    standalone: true,
    imports: [NgClass],
})
export class ToastComponent implements OnInit {
    messages: ToastMessage[] = [];

    constructor(private toastService: ToastService) {}

    ngOnInit() {
        this.toastService.toastState$.subscribe((message: ToastMessage) => {
            this.messages.push(message);
            setTimeout(() => this.removeMessage(message), 3000);
        });
    }

    removeMessage(message: ToastMessage) {
        this.messages = this.messages.filter((msg) => msg !== message);
    }
}
