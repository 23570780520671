<m-modal
    [canClose]="false"
    title="{{'vehicleDialog.selectVehicles' | translate}}"
>
    <ng-container body>
        <h3 class="mb-16">{{ "vehicleDialog.selectConfiguredVehicles" | translate }}:</h3>

        <div class="vehicles mb-24">
            @for (defaultVehicle of defaultVehicles; track defaultVehicle.deviceCode) {
            <app-vehicle
                (vehicleSelected)="onVehicleSelected($event)"
                [vehicle]="defaultVehicle"
            />
            }
        </div>

        <h3 class="mb-16">{{ 'vehicleDialog.selectFromOtherVehicles' | translate }}:</h3>

        <div class="mb-16">
            <input
                class="input--text"
                [formControl]="searchControl"
                placeholder="Search vehicle"
                type="text"
            />
        </div>

        <div class="vehicles mb-24">
            @for (otherVehicle of filteredVehicles$ | async; track otherVehicle.deviceCode) {
            <app-vehicle
                (vehicleSelected)="onVehicleSelected($event)"
                [vehicle]="otherVehicle"
            />
            }
        </div>

        <h3 class="mb-16">Wjet:</h3>

        <div class="vehicles">
            @for (wjet of wJetVehicles; track wjet.deviceCode) {
            <app-vehicle
                (vehicleSelected)="onVehicleSelected($event)"
                [vehicle]="wjet"
            />
            }
        </div>
    </ng-container>

    <ng-container footer>
        <div class="flex justify-content-end align-items-center">
            <button
                class="btn--yellow"
                (click)="closeModal()"
            >
                {{ usedInSetup() ? "Volgende" : "Save" }}
            </button>
        </div>
    </ng-container>
</m-modal>
