import { CommonModule } from "@angular/common";
import { Component, inject, input, output, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownComponent } from "@maas/components/dropdown/dropdown.component";
import { ModalComponent } from "@maas/components/modal/modal.component";
import { VehicleComponent } from "@maas/components/vehicle/vehicle.component";
import { WorkerComponent } from "@maas/components/worker/worker.component";
import { DefaultComponent } from "@maas/layout/content/default/default.component";
import { AppService } from "@maas/services/app.service";
import { TranslateModule } from "@ngx-translate/core";
import { DeviceType } from "@ramudden/data-access/models/device";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { DeviceApi } from "@ramudden/data-access/resource/device.api";
import { PlannedAssignmentApi } from "@ramudden/data-access/resource/planned-assignment.api";
import { PlannedVehiclesApi } from "@ramudden/data-access/resource/planned-vehicles.api";
import moment from "moment/moment";
import { debounceTime, distinctUntilChanged, firstValueFrom, Observable, of, startWith, switchMap } from "rxjs";

type Vehicle = {
    deviceId: number;
    deviceCode: string;
    takenBy?: string;
    isWJet?: boolean;
};

export type ISelectedVehicle = {
    deviceId: number;
    deviceCode: string;
    isWJet: boolean;
    isActive: boolean;
};

@Component({
    selector: "vehicle-dialog",
    standalone: true,
    imports: [
        CommonModule,
        DefaultComponent,
        DropdownComponent,
        ReactiveFormsModule,
        ModalComponent,
        WorkerComponent,
        VehicleComponent,
        TranslateModule,
        FormsModule,
    ],
    templateUrl: "./vehicle.dialog.html",
    styleUrls: ["./vehicle.dialog.scss"],
})
export class VehicleDialog {
    usedInSetup = input<boolean>(true);

    @ViewChild(ModalComponent, { static: true }) dialog: ModalComponent;

    modalClosed = output<ISelectedVehicle[]>();

    defaultVehicles: ISelectedVehicle[] = [];
    otherVehicles: ISelectedVehicle[] = [];
    wJetVehicles: ISelectedVehicle[] = [];

    private readonly deviceApi = inject(DeviceApi);
    private readonly plannedAssignmentApi = inject(PlannedAssignmentApi);
    private readonly plannedVehicleApi = inject(PlannedVehiclesApi);
    private readonly appService = inject(AppService);

    searchControl = new FormControl("");
    filteredVehicles$: Observable<ISelectedVehicle[]>;

    async openModal() {
        this.dialog.openModal();

        const eventDate = this.appService.plannedEvent.eventDate;
        const isNightShift = this.appService.plannedEvent.isNightShift;
        const idsOfTeamMembers: number[] = this.appService.selectedWorkers.map((x) => x.id);
        const plannedEventId = this.appService.plannedEvent.id;
        const plannedAssignmentId: number = null;
        await this.getVehicles(eventDate, isNightShift, idsOfTeamMembers, plannedEventId, plannedAssignmentId);

        this.filteredVehicles$ = this.searchControl.valueChanges.pipe(
            startWith(""),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap((searchText) => this.filterVehicles(searchText)),
        );
    }

    filterVehicles(searchText: string): Observable<ISelectedVehicle[]> {
        const filterValue = searchText.toLowerCase();
        return of(this.otherVehicles.filter((vehicle) => vehicle.deviceCode.toLowerCase().includes(filterValue)));
    }

    closeModal() {
        const selectedVehicles = [
            ...this.defaultVehicles.filter((vehicle) => vehicle.isActive),
            ...this.otherVehicles.filter((vehicle) => vehicle.isActive),
            ...this.wJetVehicles.filter((vehicle) => vehicle.isActive),
        ];

        if (selectedVehicles.length === 0) {
            alert("Please select at least one vehicle.");
            return;
        }

        this.dialog.closeModal();
        this.modalClosed.emit(selectedVehicles);
    }

    protected convertVehicleToSelectedVehicle(vehicle: Vehicle): ISelectedVehicle {
        const isActive = this.appService.plannedEvent.plannedVehicles.some((pv) => pv.deviceId === vehicle.deviceId);

        return {
            deviceId: vehicle.deviceId,
            deviceCode: vehicle.deviceCode,
            isWJet: vehicle.isWJet,
            isActive: isActive,
        };
    }

    protected onVehicleSelected(vehicle: ISelectedVehicle) {
        if (this.defaultVehicles.some((defaultVehicle) => defaultVehicle.deviceId === vehicle.deviceId)) {
            this.defaultVehicles.find((defaultVehicle) => defaultVehicle.deviceId === vehicle.deviceId).isActive =
                !vehicle.isActive;
        } else if (this.otherVehicles.some((otherVehicle) => otherVehicle.deviceId === vehicle.deviceId)) {
            this.otherVehicles.find((otherVehicle) => otherVehicle.deviceId === vehicle.deviceId).isActive =
                !vehicle.isActive;
        } else if (this.wJetVehicles.some((wJetVehicle) => wJetVehicle.deviceId === vehicle.deviceId)) {
            this.wJetVehicles.find((wJetVehicle) => wJetVehicle.deviceId === vehicle.deviceId).isActive =
                !vehicle.isActive;
        }
    }

    //region Get Vehicles
    async getVehicles(
        eventDate: Date,
        isNightShift: boolean,
        idsOfTeamMembers: number[],
        plannedEventId: number,
        plannedAssignmentId?: number,
    ) {
        const takenVehicleServiceRequestOptions = new ServiceRequestOptions();
        takenVehicleServiceRequestOptions.includes.add("PlannedVehicle", "PlannedEvent");
        takenVehicleServiceRequestOptions.includes.add("PlannedVehicle", "Device");
        takenVehicleServiceRequestOptions.includes.add("PlannedEvent", "PlannedWorkers");
        takenVehicleServiceRequestOptions.includes.add("PlannedWorker", "Worker");

        const searchParametersNotAvailable = new SearchParameters();
        searchParametersNotAvailable.filter = [];
        searchParametersNotAvailable.filter.push(
            new FilterDescriptor("EventDate", moment(eventDate).format("YYYY/MM/DD")),
        );
        searchParametersNotAvailable.filter.push(new FilterDescriptor("IsNightShift", isNightShift));

        const allTakenVehicles = await firstValueFrom(
            this.plannedVehicleApi.getAll$(searchParametersNotAvailable, null, takenVehicleServiceRequestOptions),
        );
        const allTakenVehiclesByTeam = allTakenVehicles
            .filter((x) => x.plannedEventId === plannedEventId)
            .map((x) => x.device);

        if (plannedAssignmentId !== null) {
            const customVehiclesServiceRequestOptions = new ServiceRequestOptions();
            customVehiclesServiceRequestOptions.includes.add("PlannedAssignment", "CustomVehicles");
            customVehiclesServiceRequestOptions.includes.add("PlannedAssignmentCustomVehicle", "Device");

            const plannedAssignment = await firstValueFrom(
                this.plannedAssignmentApi.get$(plannedAssignmentId, null, customVehiclesServiceRequestOptions),
            );
            if (plannedAssignment.customVehicles.length > 0) {
                allTakenVehiclesByTeam.push(...plannedAssignment.customVehicles.map((x) => x.device));
            }
        }

        const allVehiclesSearchParameters = new SearchParameters();
        allVehiclesSearchParameters.filter = [];
        allVehiclesSearchParameters.filter.push(
            new FilterDescriptor(
                "TypeId",
                `${DeviceType.Vehicle.toString()}|${DeviceType.WJet.toString()}`,
                FilterOperator.in,
            ),
        );
        allVehiclesSearchParameters.queryParams = { organizationId: this.appService.selectedWorker.organizationId };

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Device", "WorkerDriver");

        const allVehicles = await firstValueFrom(
            this.deviceApi.getAll$(allVehiclesSearchParameters, null, serviceRequestOptions),
        );
        const wJetDevices = allVehicles.filter((x) => x.typeId === DeviceType.WJet);

        const vehicleDevices = allVehicles.filter((x) => x.typeId === DeviceType.Vehicle);
        const vehiclesForWhichTeamMembersAreDrivers = vehicleDevices.filter(
            (x) => x.workerDriver !== undefined && idsOfTeamMembers.contains(x.workerDriver?.id),
        );

        let remainingVehicles = vehicleDevices.filter(
            (d) =>
                allTakenVehiclesByTeam.some((x) => x.id === d.id) || !allTakenVehicles.some((v) => v.deviceId === d.id),
        );
        remainingVehicles = remainingVehicles.filter(
            (d) => !vehiclesForWhichTeamMembersAreDrivers.some((v) => v.id === d.id),
        );

        const teamVehicles: Vehicle[] = [];
        vehiclesForWhichTeamMembersAreDrivers.forEach((teamVehicle) => {
            let vehicle: Vehicle = {
                deviceId: teamVehicle.id,
                deviceCode: teamVehicle.code,
                isWJet: false,
            };
            const isTaken =
                allTakenVehicles.some((x) => x.deviceId === teamVehicle.id) &&
                !allTakenVehiclesByTeam.some((x) => x.id === teamVehicle.id);
            if (isTaken) {
                let plannedVehicle = allTakenVehicles.find((x) => x.deviceId === teamVehicle.id);

                if (plannedVehicle) {
                    const teamLeader = plannedVehicle.plannedEvent.plannedWorkers.sort(
                        (a, b) => a.displayOrder - b.displayOrder,
                    )[0].worker;
                    vehicle.takenBy = `${teamLeader.firstName} ${teamLeader.lastName}`;
                }
            }

            teamVehicles.push(vehicle);
        });

        const notTakenVehicles = remainingVehicles.map((remainVehicle) => {
            return {
                deviceId: remainVehicle.id,
                deviceCode: remainVehicle.code,
                isWJet: false,
            } as Vehicle;
        });

        const wJetVehicles: Vehicle[] = [];
        wJetDevices.forEach((wJetDevice) => {
            let vehicle: Vehicle = {
                deviceId: wJetDevice.id,
                deviceCode: wJetDevice.code,
                isWJet: true,
            };
            const isTaken =
                allTakenVehicles.some((x) => x.deviceId === wJetDevice.id) &&
                !allTakenVehiclesByTeam.some((x) => x.id === wJetDevice.id);

            if (isTaken) {
                let plannedVehicle = allTakenVehicles.find((x) => x.deviceId === wJetDevice.id);

                if (plannedVehicle !== null) {
                    const teamLeader = plannedVehicle.plannedEvent.plannedWorkers.sort(
                        (a, b) => a.displayOrder - b.displayOrder,
                    )[0].worker;
                    vehicle.takenBy = `${teamLeader.firstName} ${teamLeader.lastName}`;
                }
            }

            wJetVehicles.push(vehicle);
        });

        this.defaultVehicles = teamVehicles.map((x) => this.convertVehicleToSelectedVehicle(x));
        this.otherVehicles = notTakenVehicles.map((x) => this.convertVehicleToSelectedVehicle(x));
        this.wJetVehicles = wJetVehicles.map((x) => this.convertVehicleToSelectedVehicle(x));
    }

    //endregion
}
