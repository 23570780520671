<nav class="nav">
    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'details']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="file-lines" />
        <span>Opdracht</span>
    </a>

    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'tasks']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="task" />
        <span>Taken</span>
    </a>

    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'parking-bans']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="parking" />
        <span>Parkeren</span>
    </a>

    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'signs']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="signs" />
        <span>Borden</span>
    </a>
</nav>
