import { Injectable } from "@angular/core";
import { IJournalSubCategory, JournalSubCategorySaver } from "@ramudden/data-access/models/journal";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class JournalSubCategoryApi extends ApiService<
    IJournalSubCategory,
    JournalSubCategorySaver,
    JournalSubCategorySaver
> {
    override getRoute(): string {
        return "JournalSubCategories";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}
