import { inject, Injectable } from "@angular/core";
import { IAddress, IAddressWithTimeZone, ICoordinate } from "@ramudden/data-access/models/location";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocationService {
    private readonly locationApi = inject(LocationApi);

    async getCurrentCoordinates(): Promise<ICoordinate> {
        if (!navigator.geolocation) {
            throw new Error("Geolocation is not supported by this browser.");
        }

        return new Promise<ICoordinate>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coordinates: ICoordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    resolve(coordinates);
                },
                (error) => {
                    reject(error);
                },
                { enableHighAccuracy: true },
            );
        });
    }

    convertToAddressString(address: IAddress, useCountry: boolean = false): string {
        return `${address.line1}, ${address.zipCode} ${address.city}${useCountry ? `\n${address.country}` : ""}`;
    }

    async getAddressFromCoordinates(coordinates?: ICoordinate): Promise<IAddressWithTimeZone> {
        if (!coordinates) {
            coordinates = await this.getCurrentCoordinates();
        }
        return await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinates));
    }

    async getCoordinatesFromAddress(address: IAddress): Promise<ICoordinate> {
        return await firstValueFrom(this.locationApi.getCoordinatesFromAddress$(address));
    }
}
