<header class="header">
    @if (titleService.goBack() !== undefined) {
        <nav class="header__nav--left nav">
            <a
                class="btn--icon"
                [routerLink]="titleService.goBack()"
                (click)="titleService.goBack.set(undefined)"
                title="Terug"
            >
                <m-svg iconName="back" />
            </a>
        </nav>
    }

    <h1 class="header__title">
        {{ titleService.title }}
    </h1>

    @if (appService.config?.isConfigured) {
        <nav class="header__nav--right nav">
            <button
                class="btn--white"
                (click)="endWorkday()"
                title="Beeindig werkdag"
            >
                <span>Beeindig werkdag</span>
                <m-svg iconName="stopwatch" />
            </button>

            <button
                class="btn--icon"
                (click)="userIconClick()"
                title="{{ appService.selectedWorker.firstName + ' ' + appService.selectedWorker.lastName }}"
            >
                <m-svg iconName="user" />
            </button>

            <button
                class="btn--icon"
                (click)="refreshClicked()"
                title="Reload"
            >
                <m-svg iconName="reload" />
            </button>
        </nav>
    }
</header>
