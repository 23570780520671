<div
    class="dropdown"
    (blur)="onblur()"
>
    <button
        class="dropdown__button"
        #dropdownButton
        [disabled]="isDisabled"
        (click)="toggleDropdown()"
        type="button"
    >
        <span class="dropdown__button__label">{{ fieldValue }}</span>

        <m-svg
            class="dropdown__button__icon"
            iconName="marker-down"
        />
    </button>

    <div
        class="dropdown__content"
        #dropdownContent
        [class.is-visible]="isDropdownOpen"
        (clickOutside)="isDropdownOpen = false"
    >
        <div class="dropdown__list">
            @for (option of options(); track option.label) {
                <label class="dropdown__list-item">
                    <input
                        class="input--option"
                        [type]="isMultiSelect() ? 'checkbox' : 'radio'"
                        [value]="option | json"
                        (change)="onSelectionChange($event)"
                        name="item"
                    />
                    @if (customTemplate) {
                        <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: option }" />
                    } @else {
                        {{ option.label }}
                    }
                </label>
            }
        </div>
    </div>
</div>
