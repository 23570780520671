import { Injectable } from "@angular/core";
import { ISearchResult, SearchParameters } from "@ramudden/data-access/models/search";
import { IAssignmentPlanningSummary, IAssignmentSummary } from "@ramudden/data-access/models/web";
import { Observable } from "rxjs";
import { WebApiService } from "./web-api";

@Injectable({
    providedIn: "root",
})
export class AssignmentWebApi extends WebApiService<IAssignmentSummary> {
    override getRoute(): string {
        return "Assignments";
    }
    getForPlanning$(searchParameters: SearchParameters): Observable<ISearchResult<IAssignmentPlanningSummary>> {
        const options = this.createOptions(null, searchParameters);
        const url = this.getUrl() + "/Planning";

        return this.http.get<ISearchResult<IAssignmentPlanningSummary>>(url, options);
    }
}
