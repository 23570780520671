import { IAttachment } from "@ramudden/data-access/models/attachment";
import { IParkingBanException } from "@ramudden/data-access/models/parking-ban-exception";
import { ISignScan } from "@ramudden/data-access/models/sign-scan";
import { IAssignment } from "./assignment";
import { ILocation, LocationCreator } from "./location";
import { IServiceModelBase } from "./servicemodelbase";

export interface IParkingBan extends IServiceModelBase {
    name: string;
    description: string;
    isCompleted: boolean;
    start?: Date;
    end?: Date;

    assignment?: IAssignment;
    exceptions?: IParkingBanException[];
    location?: ILocation;
    photos?: IAttachment[];
    signScans?: ISignScan[];
}

export class ParkingBanCreator {
    name: string;
    description: string;
    isCompleted: boolean;
    start?: Date;
    end?: Date;

    assignmentId?: number;
    locationId?: number;
    location?: LocationCreator;
}

export class ParkingBanUpdater extends ParkingBanCreator {
    id: number;

    init(parkingBan: IParkingBan): ParkingBanUpdater {
        this.start = parkingBan?.start;
        this.end = parkingBan?.end;
        this.id = parkingBan.id;
        this.name = parkingBan.name;
        this.description = parkingBan.description;
        this.isCompleted = parkingBan.isCompleted;
        this.assignmentId = parkingBan.assignment ? parkingBan.assignment.id : undefined;
        this.locationId = parkingBan.location ? parkingBan.location.id : undefined;

        return this;
    }
}
