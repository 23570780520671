import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi,
} from "@angular/common/http";
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { MSAL_INSTANCE, MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AppComponent } from "@maas/app.component";
import { SigncoErrorHandler } from "@maas/error-handler";
import { AppConfiguredGuard } from "@maas/guards/app-configured.guard";
import { AddWorkerInterceptor } from "@maas/interceptor/add-worker.interceptor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AuthGuard } from "@ramudden/guards";
import { AuthInterceptor, DefaultInterceptor, JsonInterceptor } from "@ramudden/interceptors";
import { ConfigurationService, DomainDataService } from "@ramudden/services";
import { Angulartics2Module } from "angulartics2";
import { provideToastr } from "ngx-toastr";
import { appRoutes } from "./app.routes";

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./i18n/", ".json");
}

export function msalConfigFactory(configurationService: ConfigurationService): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: configurationService.configuration.azureAuthenticationClientId,
            authority: configurationService.configuration.azureAuthenticationAuthority,
            redirectUri: window.location.origin,
            knownAuthorities: [configurationService.configuration.azureAuthenticationAuthority],
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            loggerOptions: {
                loggerCallback(_logLevel: LogLevel, message: string) {
                    console.log(message);
                },
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false,
            },
        },
    });
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes),
        provideServiceWorker("ngsw-worker.js", {
            enabled: !location.hostname.includes("localhost"),
            registrationStrategy: "registerWhenStable:30000",
        }),
        importProvidersFrom(Angulartics2Module.forRoot()),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: httpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),
        provideToastr(),
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigurationService, domainDataService: DomainDataService) => async () => {
                await AppComponent.attemptStartup(configService, domainDataService);
            },
            deps: [ConfigurationService, DomainDataService],
            multi: true,
        },
        AuthGuard,
        AppConfiguredGuard,
        [
            {
                provide: ErrorHandler,
                useClass: SigncoErrorHandler,
            },
        ],
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddWorkerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalConfigFactory,
            deps: [ConfigurationService],
        },
        MsalService,
        MsalBroadcastService,
    ],
};
