import { Injectable } from "@angular/core";
import { AlertConfigurationGroupCreator, IAlertConfigurationGroup } from "@ramudden/data-access/models/alert";
import { IDateInfoCollection } from "@ramudden/data-access/models/date-info";
import { IProject, ProjectCreator, ProjectUpdater } from "@ramudden/data-access/models/project";
import { ISearchResult } from "@ramudden/data-access/models/search";
import { firstValueFrom, Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ProjectApi extends ApiService<IProject, ProjectCreator, ProjectUpdater> {
    override getRoute(): string {
        return "Projects";
    }

    getRoot(projectId: number): Observable<IProject> {
        const url = `${super.getUrl()}/${projectId}/root`;
        return this.http.get<IProject>(url);
    }

    getDataDates$(projectId: number): Observable<IDateInfoCollection> {
        const url = `${super.getUrl()}/${projectId}/Dates`;
        return this.handleCaching(url);
    }

    createAlertConfigurationGroups$(
        projectId: number,
        creator: AlertConfigurationGroupCreator,
    ): Observable<IAlertConfigurationGroup[]> {
        const url = `${super.getUrl()}/${projectId}/AlertConfigurationGroups`;
        return this.http.post<IAlertConfigurationGroup[]>(url, creator);
    }

    importAlertConfigurationTemplate$(projectId: number, templateId: number): Observable<void> {
        const url = `${super.getUrl()}/${projectId}/ImportAlertConfigurationTemplate/${templateId}`;
        return this.http.post<void>(url, null);
    }

    public async getAll(): Promise<ISearchResult<IProject>> {
        const result = await firstValueFrom(this.http.get<ISearchResult<IProject>>(this.getUrl()));
        return result;
    }

    async calculateAlerts$(projectId: number): Promise<void> {
        const url = `${super.getUrl()}/${projectId}/CalculateAlerts`;
        const result = await firstValueFrom(this.http.post<void>(url, null));
        return result;
    }
}
