import { Route } from "@angular/router";
import { AppConfiguredGuard } from "@maas/guards/app-configured.guard";
import { PendingChangesGuard } from "@maas/guards/pending-changes.guard";
import { AssignmentContainerComponent } from "@maas/pages/assignment-container/assignment-container.component";
import { AssignmentDetailsComponent } from "@maas/pages/assignment-container/assignment-details/assignment-details.component";
import { AssignmentParkingBansComponent } from "@maas/pages/assignment-container/assignment-parking-bans/assignment-parking-bans.component";
import { CreateParkingBanComponent } from "@maas/pages/assignment-container/assignment-parking-bans/create-parking-ban/create-parking-ban.component";
import { EditParkingBanComponent } from "@maas/pages/assignment-container/assignment-parking-bans/edit-parking-ban/edit-parking-ban.component";
import { AssignmentSignsComponent } from "@maas/pages/assignment-container/assignment-signs/assignment-signs.component";
import { AssignmentTasksComponent } from "@maas/pages/assignment-container/assignment-tasks/assignment-tasks.component";
import { TaskDetailsComponent } from "@maas/pages/assignment-container/assignment-tasks/task-details/task-details.component";
import { AssignmentsComponent } from "@maas/pages/assignments/assignments.component";
import { RootComponent } from "@maas/pages/root.component";
import { SetupComponent } from "@maas/pages/setup/setup.component";
import { AuthGuard } from "@ramudden/guards";

export const appRoutes: Route[] = [
    {
        path: "",
        component: RootComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "setup",
        component: SetupComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "assignments",
        component: AssignmentsComponent,
        canActivate: [AppConfiguredGuard],
    },
    {
        path: "assignment/:plannedAssignmentId",
        component: AssignmentContainerComponent,
        canActivate: [AppConfiguredGuard],
        children: [
            {
                path: "details",
                component: AssignmentDetailsComponent,
            },
            {
                path: "tasks",
                component: AssignmentTasksComponent,
            },
            {
                path: "parking-bans",
                component: AssignmentParkingBansComponent,
            },
            {
                path: "signs",
                component: AssignmentSignsComponent,
            },
        ],
    },
    {
        path: "assignment/:plannedAssignmentId/parking-ban/create",
        component: CreateParkingBanComponent,
        canActivate: [AppConfiguredGuard],
    },
    {
        path: "assignment/:plannedAssignmentId/parking-ban/:parkingBanId",
        component: EditParkingBanComponent,
        canActivate: [AppConfiguredGuard],
        canDeactivate: [PendingChangesGuard],
    },
    {
        path: "assignment/:plannedAssignmentId/task/:taskId",
        component: TaskDetailsComponent,
        canActivate: [AppConfiguredGuard],
    },
    {
        path: "**",
        redirectTo: "",
    },
];
