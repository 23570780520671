<user-dialog
    #userDialog
    (modalClosed)="onUserDialogClosed($event)"
/>

<team-dialog
    #teamDialog
    (modalClosed)="onTeamDialogClosed($event)"
/>

<vehicle-dialog
    #vehicleDialog
    (modalClosed)="onVehicleDialogClosed($event)"
/>
