<div class="content-default">
    <div class="content-default__body">
        <div class="container">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>

    <div class="content-default__floating-action">
        <ng-content select="[floating-action]"></ng-content>
    </div>
</div>

